import React, { useEffect } from 'react'
import { Table, Input, Button } from 'antd';
import { Form, Formik } from "formik";
import {
    CheckCircleTwoTone,
    SearchOutlined,
    LeftOutlined
} from '@ant-design/icons'
import { useHistory, withRouter, useRouteMatch } from "react-router";
import './question.scss'
import AuthContainer from '../../../store/container/AuthContainer';
import QuestionService from '../../../services/QuestionService/question.service';
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from '../../../enums/notificationTypes';
import { QuestionTypes } from '../../../enums/questionTypes';
import { convertJSONToFormData } from '../../utils/formDataConvertor';

const Question = (props: any) => {
    let match = useRouteMatch();
    const history = useHistory()
    const questionId = props.location.state
    var { error, loading, question, getQuestion, editQuestion, editTopicQuestion, getTopicQuestion } = QuestionService();
    const module = window.location.pathname.split('/')[1]
    const topicId = window.location.pathname.split('/')[2]

    const optionImage = (element: any) => element?.imageUrl !== null
    const isGrid = question?.options?.some(optionImage)

    useEffect(() => {
        module === "topics" ?
            getTopicQuestion(topicId, questionId) :
            getQuestion(questionId)
    }, [question?.status])

    const successHandler = () => {
        Notification({
            message: "Status",
            description: "Status updated successfully",
            type: NotificationTypes.SUCCESS,
        });
        history.goBack()
    }

    const editQuestionStatus = (status: string) => {
        const options = question?.options?.map((question)=>question?.id)
        const payload = convertJSONToFormData({"question": {status, options}})
        module === "topics" ?
            editTopicQuestion(topicId, questionId, payload,  successHandler, ()=>{}) :
            editQuestion(questionId, payload,  successHandler, ()=>{})
    }

    return (
        <div className="app-wrapper pending-approval__question">
            <div className="pending-approval__question-header">
                <div className="title">
                    <div className="">
                        <LeftOutlined onClick={history.goBack} />
                        <h1 onClick={history.goBack}>
                            {
                                module === "questions" ?
                                    question?.id :
                                    question?.title
                            }
                        </h1>
                        {
                            module === "questions" ?
                                null :
                                <Button type="primary">Public</Button>
                        }
                    </div>
                    {
                        module === "questions" ?
                            <div className="">
                                <p>CREATED BY: <span>
                                    {question?.createdBy}</span>
                                </p>
                            </div> :
                            null
                    }
                </div>
            </div>
            <div className="pending-approval__question-content">
                <div className="texts">
                    <div>
                        <p>Question</p>
                        <h2>{question?.title}</h2>
                    </div>
                    <div>
                        <p className="answer-header">Answers</p>
                        <div className={isGrid ? "answers_grid" : "answers_flex"}>
                            {
                                question?.options?.map((answer) => (
                                    // answer?.imageUrl ?
                                        <div className="answer_item">
                                            <h3>{answer?.text}</h3>
                                            {
                                                answer?.imageUrl &&
                                                <img src={answer?.imageUrl} alt="" />
                                            }
                                        </div>
                                        
                                        // <h3>{answer?.text}</h3>
                                ))
                            }
                        </div>
                        <p className="correct_answer-header">Correct Answer</p>
                        {question?.correctAnswer?.text &&
                            <h3>
                                <CheckCircleTwoTone twoToneColor="#40CE2A" />
                                <span>{question?.correctAnswer?.text}</span>
                            </h3>
                        }
                        <img className='correct_answer_image' src={question?.correctAnswer?.imageUrl} alt="" />
                    </div>
                </div>
                <div className="image">
                    <div className="image-wrapper" style={{ height: "320px", width: "100%" }}>
                        {
                            question?.imageUrl &&
                            <img src={question?.imageUrl} alt="" />
                        }
                    </div>
                    <div className="actions">
                        {
                            question?.status === QuestionTypes.PENDING_APPROVAL ?
                                <>
                                    <Button type="primary" className="danger" onClick={() => editQuestionStatus(QuestionTypes.REJECTED)}>Reject</Button>
                                    <Button type="primary" className="success" onClick={() => editQuestionStatus(QuestionTypes.APPROVED)}>Approve</Button>
                                </> :
                                question?.status === "approved" ?
                                    <>
                                        <Button type="primary" className="outlined" onClick={() => editQuestionStatus(QuestionTypes.REJECTED)}>Unnapprove</Button>
                                        <Button type="primary" className="success">Approved</Button>
                                    </> :
                                    question?.status === QuestionTypes.REJECTED ?
                                        <>
                                            <Button type="primary" className="danger">Rejected</Button>
                                        </> :
                                        null
                        }
                    </div>
                </div>
                {
                    (question?.status === "approved" || question?.status === QuestionTypes.PENDING_APPROVAL) ?
                        <div className="edit-icon" onClick={() => history.push(`${match.url}/edit`, question)}>
                            <i className="icon-edit-2"></i>
                        </div> :
                        null
                }
            </div>
        </div>
    )
}

export default AuthContainer(Question)
