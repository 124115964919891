import React from 'react';
import AppRoutes from './routes';
import { Provider } from "react-redux";
import { store } from "./store";
import 'antd/dist/antd.css';
import "./styles/_main.scss";
import LocalStorage from './shared/localStorage';
import { AUTHENTICATED, UNAUTHENTICATED } from './store/definitions/authConstants';

const App = () => {
  const user = LocalStorage.getItem("tiger-token")

  if (user) {
    store.dispatch({
      type: AUTHENTICATED,
      payload: {
        authenticated: true,
        user: user
      }
    });
  } else {
    store.dispatch({
      type: UNAUTHENTICATED,
      payload: {
        authenticated: false,
        user: null
      }
    });
  }

  return (
    <Provider store={store}>
      <div className="App">
        <AppRoutes />
      </div>
    </Provider>
  );
}

export default App;
