import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import LocalStorage from "../../shared/localStorage";
import {  CreateTest, HiringTest, HiringTests, Tests, UserReport, UserReports, ViewHiringTest, ViewHiringTestAttempts } from "../../models/Tests/tests.model";

const TestService = () => {
    const [tests, setTests] = useState<Tests>();
    const [hiringTests, setHiringTests] = useState<HiringTests>();
    const [hiringTest, setHiringTest] = useState<ViewHiringTest>();
    const [hiringTestAttempts, setHiringTestAttempts] = useState<ViewHiringTestAttempts>();
    const [userReports, setUserReports] = useState<UserReports>();
    const [certificateTests, setCertificateTests] = useState<HiringTests>();

    const [error, setError] = useState<Error>();

    const [loading, setLoading] = useState(false);

    const getTests = (topicId:any) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TESTS.replace(":topicId",topicId))
            .then((response) => {
                const _tests = deserialize(Tests, response.data);
                setTests(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getHiringTests = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_HIRING_TESTS)
            .then((response) => {
                const _tests = deserialize(HiringTests, response.data["hiring_tests"]);
                setHiringTests(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getHiringTest = (testId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_HIRING_TEST.replace(":testId",testId))
            .then((response) => {
                const _tests = deserialize(ViewHiringTest, response.data["test"]);
                setHiringTest(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getHiringTestAttempts = (testId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_HIRING_TEST_USERS.replace(":testId",testId))
            .then((response) => {
                const _tests = deserialize(ViewHiringTestAttempts, response.data);
                setHiringTestAttempts(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUserReport = (testId:string,userId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_USER_REPORT.replace(":testId",testId).replace(":attemptId",userId))
            .then((response) => {
                const _user = deserialize(UserReports, response.data);
                setUserReports(_user);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getCertificateTests = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_CERTIFICATE_TESTS)
            .then((response) => {
                const _tests = deserialize(HiringTests, response.data["certificate_tests"]);
                setCertificateTests(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createTest = (test: CreateTest,topicId:any) => {
        const _test={
            "test":serialize(CreateTest,test)
        }
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.GET_TESTS.replace(":topicId",topicId),_test)
            .then((response) => {
                // const _question = deserialize(CreateQuestion, response.data["question"]);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteTest = (testId:any) => {
        setLoading(true);
        return axiosInstance
            .delete(ApiRoutes.DELETE_TEST.replace(":testId",testId))
            .then((response) => {
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        tests,
        error,
        loading,
        getTests,
        createTest,
        getHiringTests,
        hiringTests,
        getCertificateTests,
        certificateTests,
        getHiringTest,
        hiringTest,
        getUserReport,
        userReports,
        deleteTest,
        getHiringTestAttempts,
        hiringTestAttempts
    };
};

export default TestService;
