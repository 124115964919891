import React, { useState } from "react"
import { Formik, Form, FormikProps, Field, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Modal, Row } from "antd";
import { Upload, message, Input } from 'antd';
import './topicform.scss';
import { useHistory, withRouter, useRouteMatch } from "react-router";
import TopicService from "../../../services/TopicService/topic.service";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import topicImage from '../../../assets/images/topic.png'
import { topicValidation } from './topicValidation'
import { convertJSONToFormData } from "../../../shared/utils/formDataConvertor";

const { Dragger } = Upload;
const { TextArea } = Input;

const TopicForm = (props?: any) => {
    const { createTopic, editTopic, deleteTopic, error, loading } = TopicService()
    const { topic } = props
    const history = useHistory()
    const module = window.location.pathname.split('/')[2]
    const [successModalVisible, setSuccessModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [url, setUrl] = useState("")

    const openSuccessModal = () => {
        setSuccessModalVisible(true)
    }

    const closeSuccessModal = () => {
        setSuccessModalVisible(false)
        history.goBack()
    }

    const openDeleteModal = () => {
        setDeleteModalVisible(true)
    }

    const closeDeleteModal = () => {
        setDeleteModalVisible(false)
    }

    const onSubmit = (values: FormikValues) => {
        typeof values?.image==="string" && delete values.image;
        topic ?
            // module === "proposed-topic" ?
            //     editProposedTopic(convertJSONToFormData({"topic":values}), topic?.id) :
                editTopic(convertJSONToFormData({"topic":values}), topic?.id) :
            createTopic(convertJSONToFormData({"topic":values}))

        if (error) {
            Notification({
                message: "Error",
                description: `Error occured while ${topic ? "editing" : "creating"} a topic, please try again!`,
                type: NotificationTypes.ERROR,
            });
        } else {
            if (topic) {
                Notification({
                    message: `Topic edited successfully`,
                    description: "",
                    type: NotificationTypes.SUCCESS,
                })
                history.goBack()
            } else {
                openSuccessModal()
            }
        }
    }
    const handleDelete = () => {
        // module === "proposed-topic" ?
        //     deleteProposedTopic(topic?.id) :
            deleteTopic(topic?.id)
        if (error) {
            Notification({
                message: "Error",
                description: `Error occured while deleting the topic, please try again!`,
                type: NotificationTypes.ERROR,
            });
        } else {
            Notification({
                message: `Topic deleted successfully`,
                description: "",
                type: NotificationTypes.SUCCESS,
            });
            history.goBack()
            history.goBack()
        }
    }


    return (
        <div className="topic-form__container">
            <Formik
                initialValues={{ name: topic?.name, description: topic?.description, image: topic?.imageUrl }}
                onSubmit={onSubmit}
                validationSchema={topicValidation}
            >
                {({ values, setFieldValue, dirty, isValid }) => (

                    <Form className="topic__form">
                        <div className="topic__form-fields">
                            {
                                topic ?
                                    <Button className="delete-btn" type="primary" onClick={openDeleteModal}> <i className="icon-delete"></i> Delete</Button>
                                    : null
                            }
                            <InputField title={topic?.name && "Title"} type="text" name="name" placeholder="Topic name" />
                            <Field
                                as={Dragger}
                                onChange={(info: any) => {
                                    const { status } = info.file;
                                    if (status !== 'uploading' && status!=="removed") {
                                        setFieldValue("image", info?.file)
                                        setUrl(URL.createObjectURL(info?.file))
                                    }else{
                                        setFieldValue("image", "")
                                        setUrl("")
                                    }
                                }}
                                onDrop={(e: any) => {
                                    setFieldValue("image", e.dataTransfer.files[0])
                                }}
                                accept="image/*"
                                name="image"
                                beforeUpload={() => false}
                                multiple={false}
                            >
                                {topic && !url?
                                <img src={topic?.imageUrl} alt="" />:
                                url!==""&&<img src={url} alt="" />
                                }
                                <h3 className="ant-upload-text">
                                    <i className="icon-upload"></i>
                                    Upload image</h3>
                            </Field>
                            <Field
                                as={TextArea}
                                title={topic?.name && "Description"}
                                name="description"
                                placeholder="Description"
                                rows={1}
                                autoSize={true}
                            />
                        </div>
                        <div className="topic__form-actions">
                            <Button type="text" onClick={history.goBack}>Cancel</Button>
                            <Button type="primary" disabled={!isValid || !dirty} htmlType="submit">Save</Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <Modal
                className="success-modal"
                centered
                title={
                    <>
                        <i className="icon-success"></i>
                        <h2>Success!</h2>
                    </>
                }
                visible={successModalVisible}
                onCancel={closeSuccessModal}
                footer={null}>
                <h3>Your new topic has been submitted successfully.</h3>
            </Modal>
            <Modal
                className="delete-modal"
                centered
                closable={true}
                visible={deleteModalVisible}
                onCancel={closeDeleteModal}
                footer={null}>
                <h3>Are you sure you want to delete this topic?</h3>
                <Button type="primary" className="cancel" onClick={closeDeleteModal}>No</Button>
                <Button type="primary" onClick={handleDelete}>Yes</Button>
            </Modal>
        </div>
    )
}

export default TopicForm
