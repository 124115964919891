import React from 'react'
import TopicForm from '../TopicForm'
import {
    LeftOutlined
} from '@ant-design/icons'
import { useHistory } from "react-router";
import '../Create/create.scss'

const Edit=(props:any)=> {
    const history = useHistory()
    const topic=props.location.state
    return (
        <div className="app-wrapper">
            <h1>{<LeftOutlined onClick={history.goBack} />}{topic.name}</h1>
            <TopicForm topic={topic}/>
        </div>
    )
}

export default Edit
