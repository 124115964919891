import React, { useEffect } from 'react'
import './pendingapproval.scss'
import AuthContainer from '../../../../store/container/AuthContainer'
import { Table, Input } from 'antd';
import { Form, Formik } from "formik";
import {
  SearchOutlined
} from '@ant-design/icons'
import QuestionService from '../../../../services/QuestionService/question.service';
import { useHistory, withRouter, useRouteMatch } from "react-router";
import { QuestionTypes } from '../../../../enums/questionTypes';
import ParagraphEllipsis from '../../../../shared/components/ParagraphEllipsis';

const PendingApproval = () => {
  let match = useRouteMatch();
  const history = useHistory()
  const { error, loading, getTopicQuestions, questions, question, getQuestion } = QuestionService();
  const topicId = window.location.pathname.split('/')[2]

  useEffect(() => {
    getTopicQuestions(topicId, QuestionTypes.PENDING_APPROVAL)
  }, [])

  const columns = [
    {
      title: 'QUESTION ID',
      dataIndex: 'id',
    },
    {
      title: 'QUESTION',
      dataIndex: 'title',
      render: ((_: any, row: any) => (
        <ParagraphEllipsis text={row?.title}></ParagraphEllipsis>
      )),
    },
    {
      title: 'TOPIC NAME',
      dataIndex: 'topicTest',
    },
    {
      title: 'CREATED BY',
      dataIndex: 'createdBy',
    },
  ];

  return (

    <div className="pending-approval__questions">
      <div className="questions__list">
        <Table
          loading={loading}
          onRow={(record, index) => ({
            onClick: (event) => history.push(`${match.url}/${record?.id}`, record?.id)
          })}
          columns={columns}
          dataSource={questions?.questions}
          size="middle"
          pagination={false}
          scroll={{ y: 460 }} />
      </div>
    </div>
  )
}

export default AuthContainer(PendingApproval)
