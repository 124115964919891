import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button } from "antd";
import { withRouter, useHistory } from "react-router-dom";
import { RESET, VERIFY } from "../../../routes/routeConstants/appRoutes";
import { otpSchema } from "../Validations";
import OtpInput from 'react-otp-input'
import { User } from "../../../models/Auth/user.model";
import AuthService from "../../../services/AuthService/auth.service";

const VerifyForm = (props:any) => {
    const email=props.location.state.email.email
    const { user, error, loading, forgotPasswordOTP } = AuthService();
    const history = useHistory()
    const initialValue = {
        otp: "",
    }
    const onSubmit = (otp:User) => {
        forgotPasswordOTP({...otp,email})
     }
    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={otpSchema}
            >
                {({ setFieldValue, values }) => {
                    return (

                        <Form className="verify__form">
                            <h2 className="form-header verify">Email verification</h2>
                            <p className="form__info verify">Please enter the 4-digit verification code sent to your email.</p>
                            <OtpInput
                                onChange={(otp: any) => setFieldValue("otp", otp)}
                                value={values.otp}
                                numInputs={4}
                                separator={<span></span>}
                                containerStyle={"otp-container"}
                                inputStyle={"otp-input"}
                            />
                            <Button type="primary" htmlType="submit">verify</Button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default VerifyForm;