import React, { FC } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import { RouterProps } from "../shared/types/route.type";
import Navbar from "../shared/components/Navbar";
import Topics from "../views/Topics";
import Questions from "../views/Questions";
import HiringTest from "../views/HiringTest";
import Certificates from "../views/Certificates";
import Users from "../views/Users";
import Help from "../views/Help";
import Profile from "../views/Profile";
import Topic from "../views/Topics/Topic";
import Edit from "../views/Topics/Edit";
import Create from "../views/Topics/Create";
import CreateQuestion from "../views/Topics/Topic/CreateQuestion";
import CreateTest from "../views/Topics/Topic/CreateTest";
import Question from "../shared/components/Question";
import EditQuestion from "../shared/components/EditQuestion";
import User from "../views/Users/User";
import Test from "../shared/components/Tests/Test";
import TestUser from "../shared/components/Tests/Test/TestUser";
import TestUserQuestions from "../shared/components/Tests/Test/TestUser/TestUserQuestions";
import ViewHelp from "../views/Help/ViewHelp";
import Certificate from "../views/Users/User/Certificate";
import ProposedTopic from "../views/Topics/ProposedTopic";
import EditProposedTopic from "../views/Topics/ProposedTopic/Edit";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {
	const routes: RouterProps[] = [
		{ exact: false, path: appRoutes.AUTH, component: AuthWrapper },
		{ exact: true, path: appRoutes.TOPICS, component: isAuthenticated(Topics) },
		{ exact: true, path: appRoutes.CREATE_TOPIC, component: isAuthenticated(Create) },
		{ exact: true, path: appRoutes.VIEW_TOPIC, component: isAuthenticated(Topic) },
		{ exact: true, path: appRoutes.VIEW_PROPOSED_TOPIC, component: isAuthenticated(ProposedTopic) },
		{ exact: true, path: appRoutes.EDIT_TOPIC, component: isAuthenticated(Edit) },
		{ exact: true, path: appRoutes.EDIT_PROPOSED_TOPIC, component: isAuthenticated(EditProposedTopic) },
		{ exact: true, path: appRoutes.CREATE_QUESTION, component: isAuthenticated(CreateQuestion) },
		{ exact: true, path: appRoutes.CREATE_TEST, component: isAuthenticated(CreateTest) },
		{ exact: true, path: appRoutes.VIEW_PENDING_QUESTION, component: isAuthenticated(Question) },
		{ exact: true, path: appRoutes.EDIT_PENDING_QUESTION, component: isAuthenticated(EditQuestion) },
		{ exact: true, path: appRoutes.QUESTIONS, component: isAuthenticated(Questions) },
		{ exact: true, path: appRoutes.VIEW_QUESTION, component: isAuthenticated(Question) },
		{ exact: true, path: appRoutes.EDIT_QUESTION, component: isAuthenticated(EditQuestion) },
		{ exact: true, path: appRoutes.HIRINGTEST, component: isAuthenticated(HiringTest) },
		{ exact: true, path: appRoutes.VIEW_TEST, component: isAuthenticated(Test) },
		{ exact: true, path: appRoutes.VIEW_CERTFICATE_TEST, component: isAuthenticated(Test) },
		{ exact: true, path: appRoutes.VIEW_TEST_USER, component: isAuthenticated(TestUser) },
		{ exact: true, path: appRoutes.VIEW_PUBLIC_TEST_USER, component: isAuthenticated(TestUser) },
		{ exact: true, path: appRoutes.VIEW_PUBLIC_HIRING_TEST_USER, component: isAuthenticated(TestUser) },
		{ exact: true, path: appRoutes.CERTIFICATES, component: isAuthenticated(Certificates) },
		{ exact: true, path: appRoutes.USERS, component: isAuthenticated(Users) },
		{ exact: true, path: appRoutes.USER, component: isAuthenticated(User) },
		{ exact: true, path: appRoutes.VIEW_SUBMITTED, component: isAuthenticated(Question) },
		{ exact: true, path: appRoutes.EDIT_SUBMITTED, component: isAuthenticated(EditQuestion) },
		{ exact: true, path: appRoutes.VIEW_CERTIFICATE, component: isAuthenticated(Certificate) },
		{ exact: true, path: appRoutes.HELP, component: isAuthenticated(Help) },
		{ exact: true, path: appRoutes.VIEW_HELP, component: isAuthenticated(ViewHelp) },
		{ exact: true, path: appRoutes.EDIT_HELP, component: isAuthenticated(ViewHelp) },
		{ exact: true, path: appRoutes.PROFILE, component: isAuthenticated(Profile) },
	];

	return (
		<div>
			<Router history={appHistory}>
				<Navbar />
				<div>
					<Switch>
						{routes.map((route, index) => (
							<Route
								key={index}
								{...route}
								component={route.component as FC}
							/>
						))}
						<Route
							path="/"
							render={() => <Redirect to={appRoutes.TOPICS} />}
						/>
						<Route
							path="*"
							render={() => <Redirect to={appRoutes.LOGIN} />}
						/>
					</Switch>
				</div>
			</Router>
		</div>
	);
};

export default AppRoutes;
