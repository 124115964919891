import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { useState } from "react";
import { useHistory } from "react-router";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { HelpTab, HelpTabs } from "../../models/Faq/faq.model";

const FaqService = () => {
    const history = useHistory();

    const [error, setError] = useState<Error>();
    const [helpTabs, setHelpTabs] = useState<HelpTabs>()
    const [helpTab, setHelpTab] = useState<HelpTab>()

    const [loading, setLoading] = useState(false);

    const getHelpTabs = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_HELP_TABS)
            .then((response) => {
                const _helptabs = deserialize(HelpTabs, response.data);
                setHelpTabs(_helptabs);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getHelpTab = (faqId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_HELP_TAB.replace(":id",faqId))
            .then((response) => {
                const _helptab = deserialize(HelpTab, response.data["help_tab"]);
                setHelpTab(_helptab);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editHelpTab = (faqId:string,faq:HelpTab) => {
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.GET_HELP_TAB.replace(":id",faqId),faq)
            .then((response) => {
                const _helptab = deserialize(HelpTab, response.data);
                setHelpTab(_helptab);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        error,
        loading,
        getHelpTabs,
        helpTabs,
        helpTab,
        getHelpTab,
        editHelpTab
    };
};

export default FaqService;
