import React, { useState } from "react";
import "./topicslist.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { useHistory, withRouter, useRouteMatch } from "react-router";
import { Topics } from "../../../models/Topics/topic.model";
import topicImage from './../../../assets/images/topic.png';
import { Button, Col, Popover, Row } from "antd";
import { Typography } from 'antd';
import {
  EllipsisOutlined,
} from "@ant-design/icons";

interface TopicsListProps{
  topics: any,
  cols: number
}

const TopicsList = (props: TopicsListProps) => {
  let match = useRouteMatch();
  const history = useHistory()
  const { topics,cols } = props
  const topicType = (window as any).topicType

  const popoverContent = <ul className="popover-list">
    <li onClick={(e) => {e.stopPropagation() }}>Deploy certificate</li>
  </ul>

  return (
    <Row className="topic__items" gutter={[12, 12]}>
      {
        topics?.map((topic: any) => (
          <Col xl={cols} xs={12} onClick={() => {
            cols == 8 ?
              history.push(`${match.url}/${topic?.id}`) :
              history.push(`${match.url}/proposed-topic/${topic?.id}`)
          }}>
            <div className={`topic-item ${cols==8?"topics":"proposed-topics"}`}>
              {/* <Popover placement="bottomRight" content={popoverContent} trigger="click">
                <Button type="text" className="popover-btn" onClick={(e) => e.stopPropagation()}><EllipsisOutlined /></Button>
              </Popover> */}
              <div className="header">
                <img decoding="async" src={topic.imageUrl || topicImage} alt={topic?.name}/>
              </div>
              <div className="info">
                {cols == 8 && <p className="tests-badge">{topic.noOfTests} Tests</p>}
                <h2 className="title">{topic?.name}{topic?.description?.trim().length ? ": "+topic.description : ""}</h2>
                {cols == 8 && <p className="new-questions">{topic.newQuestions} new questions</p>}
              </div>
            </div>
          </Col>
        ))
      }
    </Row>

  );
}

export default TopicsList;