import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { User, ProdUser } from "../../models/Auth/user.model";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { HOME, LOGIN, RESET, TOPICS, VERIFY } from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import LocalStorage from "../../shared/localStorage";

const AuthService = () => {
	const history = useHistory();

	const [user, setUser] = useState<ProdUser>();

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

	const loginUser = (data: ProdUser) => {
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.USER_LOGIN, {
				"user": data
			})
			.then((response) => {
				const userDetails = deserialize(ProdUser, response.data["user"]);
				store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: userDetails,
					},
				});
				LocalStorage.setItem("tiger-access-token", response?.data["token"]);
				LocalStorage.setItem("tiger-token", userDetails);
				Notification({
					message: "Login",
					description: "Logged in successfully",
					type: NotificationTypes.SUCCESS,
				});
				setUser(userDetails);
				history.push(TOPICS);
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const forgotPassword = (email: User) => {
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.USER_FORGOT_PASSWORD, {
				...email
			})
			.then((response) => {
				const status = response.data.success
				if (status) {
					history.push(VERIFY, { email });
				}
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const forgotPasswordOTP = (data: User) => {
		setLoading(true);
		const forgotJson = {
			...serialize(User, data)
		}
		return axiosInstance
			.post(ApiRoutes.USER_VERIFY_OTP, forgotJson)
			.then((response) => {
				const status = response.data.success
				if (status) {
					history.push(RESET, { data });
				}
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const resetPassword = (data: User) => {
		setLoading(true);
		delete data.password2
		delete Object.assign(data, { ["password"]: data["password1"] })["password1"];
		const resetJson = {
			...serialize(User, data)
		}
		return axiosInstance
			.post(ApiRoutes.USER_RESET_PASSWORD, resetJson)
			.then((response) => {
				const status = response.data.success
				Notification({
					message: status,
					description: "",
					type: NotificationTypes.SUCCESS,
				});
				if (status) {
					history.push(LOGIN);
				}
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const signOut = () => {
		setLoading(true);
		return axiosInstance
			.delete(ApiRoutes.USER_SIGNOUT)
			.then((response) => {
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return {
		user,
		error,
		loading,
		loginUser,
		forgotPassword,
		forgotPasswordOTP,
		resetPassword,
		signOut
	};
};

export default AuthService;
