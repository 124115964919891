import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required!')
})

export const emailSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!')
})

export const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .min(4, 'OTP has to be atleast 4 digits!')
    .required('OTP is required!')
})

export const resetSchema = Yup.object().shape({
  password1: Yup.string()
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,"Password must contain minimum eight characters, at least one letter, one number and one special character")
    .required('Password is required!'),
  password2: Yup
    .string()
    .test('match',
      'Passwords do not match',
      function (password2) {
        return password2 === this.parent.password1;
      }),
})