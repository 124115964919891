
import {serializable, alias, object, list, primitive} from 'serializr';

export class HelpTab { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('image', primitive()))
	image?: string;

}

export class HelpTabs{
    @serializable(alias("help_tabs", list(object(HelpTab))))
    helpTabs?: HelpTab[];
}