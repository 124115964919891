import { Button, Checkbox, Modal, Radio, Select, Space } from 'antd'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import InputField from '../../../../shared/components/InputField'
import './createtest.scss'
import { useHistory, withRouter, useRouteMatch } from "react-router";
import TestService from '../../../../services/TestService/test.service'
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from '../../../../enums/notificationTypes'
import { createTestValidation } from './createTestValidation'
const { Option } = Select;

const CreateTest = (props: any) => {
    const { createTest, loading, error } = TestService()
    const history = useHistory()
    let topicId = window.location.pathname.split('/')[2]
    const [successModalVisible, setSuccessModalVisible] = useState(false)
    const [difficultyLevel, setDifficultyLevel] = useState<any>()
    const { topic } = props.location.state

    const openSuccessModal = () => {
        setSuccessModalVisible(true)
    }
    const closeSuccessModal = () => {
        setSuccessModalVisible(false)
        history.goBack()
    }
    const onSubmit = (values: any) => {
        createTest(values, topicId)
        if (error) {
            Notification({
                message: "Error",
                description: `Error occured while creating a test, please try again!`,
                type: NotificationTypes.ERROR,
            });
        } else {
            openSuccessModal()
        }
    }

    return (
        <div className="app-wrapper">
            <h1>New Test</h1>
            <div className="topic-form__container">
                <Formik
                    initialValues={{ topicName: topic?.name }}
                    onSubmit={onSubmit}
                    validationSchema={createTestValidation(difficultyLevel == "easy" ? topic?.noOfEasyQuestions : difficultyLevel == "medium" ? topic?.noOfMediumQuestions : topic?.noOfDifficultQuestions)}
                >
                    {({ values, setFieldValue }) => (
                        <Form className="test__form">
                            <div className="test__form-fields">
                                <InputField title="Topic name" type="text" name="topicName" placeholder="Javascript" />
                                <InputField type="text" name="name" placeholder="Test name" />
                                <InputField type="number" name="maxNoOfAttempts" placeholder="Maximum number of attempts by individual user" />
                                <InputField type="number" name="minPassPercent" placeholder="Minimum percentage to pass" />
                                <Field
                                    as={Select}
                                    name="difficultyLevel"
                                    placeholder="Difficulty level"
                                    dropdownRender={(menu: any) => (
                                        <Radio.Group
                                            name='difficulty_level'
                                            onChange={(e: any) => {
                                                setFieldValue("difficultyLevel", e.target.value)
                                                setDifficultyLevel(e.target.value)
                                            }}>
                                            <Space direction='vertical'>
                                                {topic?.noOfEasyQuestions &&
                                                    <Radio value={"easy"}>Easy</Radio>}
                                                {topic?.noOfMediumQuestions &&
                                                    <Radio value={"medium"}>Medium</Radio>}
                                                {topic?.noOfDifficultQuestions &&
                                                    <Radio value={"hard"}>Hard</Radio>}
                                            </Space>
                                        </Radio.Group>
                                    )}></Field>
                                {/* <Select size="middle" placeholder="Question difficulty level" onChange={(e) => {
                                    setDifficultyLevel(e)
                                    setFieldValue("difficultyLevel", e)
                                }}>
                                    {topic?.noOfEasyQuestions &&
                                        <Option key="easy" value="easy"><Checkbox>Easy</Checkbox></Option>
                                    }
                                    {topic?.noOfMediumQuestions &&
                                        <Option key="medium" value="medium"><Checkbox>Medium</Checkbox></Option>
                                    }
                                    {topic?.noOfDifficultQuestions &&
                                        <Option key="hard" value="hard"><Checkbox>Hard</Checkbox></Option>
                                    }
                                </Select> */}
                                <InputField type="number" name="numberOfQuestions" placeholder="Number of questions" />
                                <InputField type="number" name="timePerQuestion" placeholder="Time per question (in seconds)" />
                                <InputField type="number" name="expiryDays" placeholder="Expiry days" />
                            </div>
                            <div className="test__form-actions">
                                <Button type="text" onClick={history.goBack}>Cancel</Button>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <Modal
                className="success-modal"
                centered
                title={
                    <>
                        <i className="icon-success"></i>
                        <h2>Success!</h2>
                    </>
                }
                visible={successModalVisible}
                onCancel={closeSuccessModal}
                footer={null}>
                <h3>You have successfully created the certification test.</h3>
            </Modal>
        </div>
    )
}

export default CreateTest
