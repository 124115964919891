import React, { useEffect } from 'react'
import { Button, Input, Row } from 'antd';
import { useHistory } from 'react-router';
import {
    LeftOutlined
} from '@ant-design/icons'
import './viewhelp.scss'
import { Formik, Form, Field, FormikValues } from "formik";
import FaqService from './../../../services/FaqService/faq.service'

const ViewHelp = (props: any) => {
    const {getHelpTab,loading,error,helpTab,editHelpTab} = FaqService()
    const { TextArea } = Input;
    const history = useHistory()
    const question = props.location.state
    const editQuestion = window.location.pathname.split('/')[3] || ""
    const faqId = window.location.pathname.split('/')[2]

    useEffect(() => {
        getHelpTab(faqId)
    }, [])

    const onSubmit = (values:FormikValues) => {
        editHelpTab(faqId,values).then(()=>{
            history.goBack()
        })
     }

    return (
        <div className="app-wrapper help__view">
            <div className="help__view-header">
                <LeftOutlined onClick={() => history.goBack()} />
                <h2>{helpTab?.title}</h2>
            </div>
            <div className="help__view-content">
                <div className="image">
                    <i className="icon-placeholder"></i>
                </div>
                {
                    editQuestion === "edit" ?
                        (
                            <Formik
                                initialValues={{ description: question?.description }}
                                onSubmit={onSubmit}
                            >
                                <Form className="help__form">
                                    <Field
                                        as={TextArea}
                                        name="description"
                                        placeholder="Description"
                                        autoSize={true}
                                    />
                                    <div className="controls">
                                        <Button type="text" onClick={() => history.goBack()}>cancel</Button>
                                        <Button type="primary" htmlType="submit">Save</Button>
                                    </div>
                                </Form>
                            </Formik>
                        ) :

                        <p>{helpTab?.description}</p>
                }

            </div>
        </div>
    )
}

export default ViewHelp
