import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button } from "antd";
import { withRouter,useHistory } from "react-router-dom";
import { VERIFY } from "../../../routes/routeConstants/appRoutes";
import { emailSchema } from "../Validations";
import { User } from "../../../models/Auth/user.model";
import AuthService from "../../../services/AuthService/auth.service";

const EmialForm = () => {
    const { user, error, loading, forgotPassword } = AuthService();
    const initialValue = {
        email: "",
    }
    const onSubmit=(email:User)=>{
        forgotPassword(email)
    }
    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={emailSchema}
            >
                <Form className="email__form">
                    <h2 className="form-header email">Enter email</h2>
                    <p className="form__info email">Please enter your email to send OTP for reset password.</p>
                    <InputField type="email" name="email" placeholder="Email" />
                    <Button type="primary" htmlType="submit">Send OTP</Button>
                </Form>
            </Formik>
        </div>
    )
}

export default EmialForm;