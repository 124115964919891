import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import Error from "../Error";
import TextArea from "antd/lib/input/TextArea";

interface InputFieldProps {
	className?: string;
	title?: string;
	type?: string;
	rows?: number;
    maxLength?: number;
	name: string;
	placeholder?: string;
	disabled?: boolean;
	addonAfter?: any;
	addonBefore?: any;
	onBlur?: (params: Event) => void;
	onFocus?: (params: Event) => void;
}

const InputField: FC<InputFieldProps> = (props) => {
	const { name, title, type } = props;
	const properties: any = { ...props };

	return (
		<div>
			<label htmlFor={name}>
				{title}
			</label>
			<Field
				as={type == "password" ? Input.Password : type==="textarea" ? TextArea : Input}
				{...properties}
			/>
			<ErrorMessage name={name}>
				{(message: string) => <Error message={message} />}
			</ErrorMessage>
		</div>
	);
};

export default InputField;
