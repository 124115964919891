
import {serializable, alias, object, list, primitive} from 'serializr';

export class Profile { 

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('image', primitive()))
	image?: string;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

}