import axios from 'axios';
import { store } from "../store";
import { REQUEST_LOGOUT, UNAUTHENTICATED } from "../store/definitions/authConstants";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import LocalStorage from '../shared/localStorage';
import { useHistory } from 'react-router';
import Notification from '../shared/components/Notification';
import { NotificationTypes } from '../enums/notificationTypes';

export const getHeaders = (): any => {
    let headers, auth;
    if (LocalStorage.getItem('tiger-access-token')) {
        auth = LocalStorage.getItem('tiger-access-token') || '';
    }
    // auth && delete auth["cache-control"]
    headers = {
        ...auth,
        'Authorization': `Bearer ${auth?.access_token}`,
    };
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 20000,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status,
            headers: response.headers
        }
    },
    (error) => {
        const { response } = error;
        if (response.status === 401) {
            store.dispatch({ type: REQUEST_LOGOUT, payload: {authenticated:false, user:null} });
        }
        else if (response.status === 422) {
            Notification({
                message: "Error",
                description: response.data["errors"]||response.data["error"],
                type: NotificationTypes.ERROR,
            });
        }
        else if (response?.status === 500) {
            Notification({
              message: "Error 500",
              description: "Please try again later!",
              type: NotificationTypes.ERROR,
            });
          }
        return Promise.reject(error);
    }
);

export default axiosInstance;
