import React, { useEffect } from 'react'
import { Row } from 'antd'
import {
    LeftOutlined
} from '@ant-design/icons'
import { useHistory } from 'react-router'
import './certificate.scss'
import badgeImage from './../../../../assets/images/certification.png'
import UserService from '../../../../services/UserService/user.service'

const Certificate = () => {
    const history = useHistory()
    const { getUserCertificate, userCertificate, loading, error } = UserService()
    const userId = window.location.pathname.split('/')[2]
    const testId = window.location.pathname.split('/')[3]

    useEffect(() => {
        getUserCertificate(testId, userId)
    }, [])

    return (
        <div className="app-wrapper certificate__view">
            <Row className="certificate__view-header">
                <LeftOutlined onClick={() => history.goBack()} />
                <p>Back</p>
            </Row>
            {
                userCertificate &&
                    <Row className="certificate__view-card">
                        <div className="certificate__view-card-header">
                            <div className="banner">
                                <i className="icon-certificate"></i>
                                <h3>Tiger Testing</h3>
                                <h1>CERTIFICATE</h1>
                                <h2>OF COMPLETION</h2>
                            </div>
                            <div className="certificate-number">
                                <p>Certificate number: {userCertificate?.id}</p>
                            </div>
                        </div>
                        <div className="certificate__view-card-info">
                            <div className="banner">
                                <p>Vivamus eget aliquam dui. Integer eu arcu vel arcu suscipit ultrices quis non mauris. Aenean scelerisque, sem eu dictum commodo, velit nisi blandit magna, quis scelerisque ipsum lectus ut libero.</p>
                                <h3>{userCertificate?.name}</h3>
                                <div className="">
                                    <i className="icon-success"></i>
                                    <p>Completed</p>
                                </div>
                                <h2>{userCertificate?.userName}</h2>
                            </div>
                            <div className="signatures">
                                <div className="date">
                                    <h3>Date</h3>
                                    <h3>{userCertificate?.passedAt?.slice(0, 10).replaceAll('-', '/')}</h3>
                                </div>
                                <div className="badge">
                                    <img src={badgeImage} alt="" />
                                </div>
                                <div className="sign">
                                    <h3>Signature</h3>
                                    <h3>{userCertificate?.testCreatedBy}</h3>
                                </div>
                            </div>
                        </div>
                    </Row>
            }
        </div>
    )
}

export default Certificate
