import React, { useEffect, useState } from "react";
import {
  Button,
  Tabs
} from "antd"
import {
  LeftOutlined
} from '@ant-design/icons'
import "./topic.scss";
import { useHistory, useRouteMatch } from "react-router";
import TopicService from "../../../services/TopicService/topic.service";
import AllQuestions from "./AllQuestions";
import PendingApproval from "./PendingApproval";
import Approved from "./Approved";
import Rejected from "./Rejected";
import AllTests from "./AllTests";

import { Typography } from 'antd';
import {
  EllipsisOutlined,
} from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const Topic = () => {
  let match = useRouteMatch();
  const history = useHistory()
  const { TabPane } = Tabs;
  const { error, loading, getTopic, topic } = TopicService();
  let topicId = window.location.pathname.split('/')[2]

  useEffect(() => {
    getTopic(topicId)
  }, [])
  
  useEffect(()=>{
    setRender(state=>!state)
  },[topic])

  const callback = (key: any) => { }

  const [render, setRender] = useState(false)
  const [ellipsis, setEllipsis] = useState(true);

  return (
    <div className="app-wrapper topic">
      <div className="topic__banner">
        <div className="title__container">
          <div className="title">
            <LeftOutlined onClick={history.goBack} />
            <h1 onClick={history.goBack}>
              {topic?.name}
            </h1>
            <i className="icon-edit-2" onClick={() => history.push(`${match.url}/edit`, topic)}></i>
          </div>
          <div className="actions">
            <Button type="primary" onClick={() => history.push(`${match.url}/createQuestion`)}>Create Question</Button>
            <Button type="primary" onClick={() => history.push(`${match.url}/createTest`, {topic})}>Create Test</Button>
          </div>
        </div>
        <div className="footer">
          <Paragraph ellipsis={ellipsis ? { rows: 1, expandable: true, symbol: 'Read more' } : false}>{topic?.description}</Paragraph>
        </div>
      </div>
      <div className="topic__menu-bar">
        <div className="topics-menubar">
          <Tabs defaultActiveKey="1" onChange={callback} onTabClick={(key, event) => {
            setRender(state => !state)
          }}>
            <TabPane tab="All Questions" forceRender={true} key="1">
              <AllQuestions render={render} />
            </TabPane>
            <TabPane tab="Pending Approval" key="2">
              <PendingApproval />
            </TabPane>
            <TabPane tab="Approved" key="3">
              <Approved />
            </TabPane>
            <TabPane tab="Rejected" key="4">
              <Rejected render={render} />
            </TabPane>
            <TabPane tab="All Tests" key="5">
              <AllTests />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Topic;