import React, { useEffect } from "react";
import { Row, Table } from "antd";
import './users.scss'
import UserService from "../../services/UserService/user.service";
import { useHistory } from "react-router";
import * as appRoutes from './../../routes/routeConstants/appRoutes';

const Users = () => {
    const { getUsers, users, loading, error } = UserService();
    const history = useHistory()

    useEffect(() => {
        getUsers()
    }, [])

    const columns = [
        {
            title: 'EMAIL',
            dataIndex: 'email',
            onFilter: (value: any, record: any) => record?.email?.indexOf(value) === 0,
            sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email)
        },
        {
            title: 'NAME',
            dataIndex: 'fullName',
            onFilter: (value: any, record: any) => record?.fullName?.indexOf(value) === 0,
            sorter: (a: any, b: any) => a?.fullName?.localeCompare(b?.fullName)
        },
        {
            title: 'NO OF TEST COMPLETED',
            dataIndex: 'testsCompleted',
            sorter: (a: any, b: any) => a?.testsCompleted - b?.testsCompleted,
        },
        {
            title: 'NO OF QUESTIONS SUBMITTED',
            dataIndex: 'questionsSubmitted',
            sorter: (a: any, b: any) => a?.questionsSubmitted - b?.questionsSubmitted,
        },
        {
            title: 'NO OF TEST DEPLOYED',
            dataIndex: 'testsDeployed',
            sorter: (a: any, b: any) => a?.testsDeployed - b?.testsDeployed,
        },
    ];

    return (
        <div className="app-wrapper users__list">
            <Row className="users__list-header">
                <h1>All Users</h1>
            </Row>
            <Row className="users__list-table">
                <Table
                    loading={loading}
                    onRow={(record, index) => ({
                        onClick: (event) => {
                            history.push(appRoutes.USER.replace(":id", `${record?.id}`), record)
                        }
                    })}
                    columns={columns}
                    dataSource={users?.users}
                    size="middle"
                    pagination={false}
                    scroll={{ y: 600 }} />
            </Row>
        </div>
    )
}

export default Users;
