import React, { useEffect } from 'react'
import './rejected.scss'
import { Table } from 'antd';
import QuestionService from '../../../../services/QuestionService/question.service';
import ParagraphEllipsis from '../../../../shared/components/ParagraphEllipsis';

const Rejected = (props: any) => {
    const { loading, getTopicQuestions, questions } = QuestionService();
    const topicId = window.location.pathname.split('/')[2]

    useEffect(() => {
        getTopicQuestions(topicId, "rejected")
    }, [props.render])

    const columns = [
        {
            title: 'QUESTION ID',
            dataIndex: 'id',
        },
        {
            title: 'QUESTION',
            dataIndex: 'title',
            render: ((_: any, row: any) => (
                <ParagraphEllipsis text={row?.title}></ParagraphEllipsis>
              )),
        },
        {
            title: 'TOPIC NAME',
            dataIndex: 'topicTest',
        },
        {
            title: 'CREATED BY',
            dataIndex: 'createdBy',
        },
    ];

    return (
        <div className="pending-approval__questions">
            <div className="questions__list">
                <Table
                    loading={loading}
                    onRow={(record, index) => ({
                        // onClick: (event) => getTopicQuestion(topicId,`${record?.id}`)
                    })}
                    columns={columns}
                    dataSource={questions?.questions}
                    size="middle"
                    pagination={false}
                    scroll={{ y: 430 }} />
            </div>
        </div>
    )
}

export default Rejected
