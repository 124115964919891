import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Drawer, Row, Table } from "antd";
import './questions.scss'
import QuestionService from "../../services/QuestionService/question.service";
import { useHistory, useRouteMatch } from "react-router";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { Form, Formik, FormikValues } from "formik";
import { QuestionTypes } from "../../enums/questionTypes";
import { Typography } from 'antd';
import {
  EllipsisOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";
import LocalStorage from "../../shared/localStorage";
import notSortedIcon from './../../assets/icons/notsorted.svg'
import sortedIcon from './../../assets/icons/sorted.svg'
import moment from 'moment'
import ParagraphEllipsis from "../../shared/components/ParagraphEllipsis";

const { Paragraph, Text } = Typography;

const Questions = (props: any) => {
  let match = useRouteMatch();
  const history = useHistory()
  const [ellipsis, setEllipsis] = useState(true);
  const { error, loading, getQuestions, questions, getTests,
    getTopicsMeta,
    getUsers,
    listTests,
    listTopics,
    listUsers } = QuestionService();

  const [visible, setVisible] = useState(false);
  const [render, setRender] = useState(false)

  const [oldValues, setOldValues] = useState(LocalStorage.getItem('questions-filter'))

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleFilter = (values: FormikValues) => {
    oldValues ?
      LocalStorage.setItem("questions-filter", { ...oldValues, ...values }) :
      LocalStorage.setItem("questions-filter", values)
    setOldValues({ ...oldValues, ...values })
    getQuestions(values)
    onClose()
  }

  useEffect(() => {
    oldValues ? getQuestions(LocalStorage.getItem("questions-filter")) : getQuestions()
    getTopicsMeta()
    getUsers()
    getTests()
  }, [])

  useEffect(() => {
    oldValues ? getQuestions(LocalStorage.getItem("questions-filter")) : getQuestions()
  }, [render])

  const columns = [
    {
      title: 'QUESTION ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a?.id - b?.id,
    },
    {
      title: 'QUESTION TYPE',
      dataIndex: 'type',
      onFilter: (value: any, record: any) => record?.type?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.type?.localeCompare(b?.type)
    },
    {
      title: 'QUESTION TITLE',
      dataIndex: 'title',
      render: ((_: any, row: any) => (
        <Paragraph ellipsis={ellipsis ? { rows: 1, expandable: false, symbol: 'more' } : false}>{row?.title}</Paragraph>
      )),
      onFilter: (value: any, record: any) => record?.title?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.title?.localeCompare(b?.title)
    },
    {
      title: 'TOPIC/TEST NAME',
      dataIndex: 'topicTest',
      render: ((_: any, row: any) => (
        <ParagraphEllipsis text={row?.topicTest}></ParagraphEllipsis>
      )),
      onFilter: (value: any, record: any) => record?.topicTest?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.topicTest?.localeCompare(b?.topicTest)
    },
    {
      title: 'CREATED BY',
      dataIndex: 'createdBy',
      render: ((_: any, row: any) => (
        <ParagraphEllipsis text={row?.createdBy}></ParagraphEllipsis>
      )),
      onFilter: (value: any, record: any) => record?.createdBy?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.createdBy?.localeCompare(b?.createdBy)
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      onFilter: (value: any, record: any) => record?.createdAt?.indexOf(value) === 0,
      sorter: (a: any, b: any) => moment(a?.createdAt, "YYYY/MM/DD HH:mm:ss").diff(moment(b?.createdAt, "YYYY/MM/DD HH:mm:ss"))
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: ((_: any, row: any) => <p className={`status ${row?.status}`}>{row?.status?.replace('_', " ")}</p>),
      onFilter: (value: any, record: any) => record?.status?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.status?.localeCompare(b?.status)
    },
  ];

  const getLabelandValue = (array: any) => {
    array?.map((values: any) => ({ label: values.name ?? values.email ?? "", value: values.id as number }))
  }

  const listStatuses = [
    {
      "id": 0,
      "type": "Approved"
    },
    {
      "id": 1,
      "type": "Pending approval"
    },
    {
      "id": 2,
      "type": "Rejected"
    }
  ]

  const listTypes = [
    {
      "id": 0,
      "type": "Certificate"
    },
    {
      "id": 1,
      "type": "Custom"
    },
  ]

  return (
    <div className="questions__list app-wrapper">
      <Row className="questions__list-header">
        <h1>All Questions</h1>
        <Button type="primary" onClick={showDrawer}>
          <i className="icon-filter"></i>
          Filter</Button>
      </Row>
      <Row className="questions__list-table all_questions" style={{ height: "100%" }}>
        <Table
          loading={loading}
          onRow={(record, index) => ({
            onClick: (event) => {
              history.push(appRoutes.VIEW_QUESTION.replace(":id", `${record?.id}`), record?.id)
              setRender(state => !state)
            }
          })}
          columns={columns}
          dataSource={questions?.questions}
          size="middle"
          pagination={false}
          scroll={{ y:600 }} />
      </Row>
      <Drawer
        title={<h1>Filters</h1>}
        placement="right"
        width="80vw"
        onClose={onClose}
        visible={visible}
        closable={true}
        maskStyle={{
          opacity: .78,
          backgroundColor: "#000"
        }}
        className="filter-drawer"
      >
        <Formik
          initialValues={{ types: oldValues?.types ?? [], topic_ids: oldValues?.topic_ids ?? [], statuses: oldValues?.statuses ?? [], created_by_ids: oldValues?.created_by_ids ?? [], test_ids: oldValues?.test_ids ?? [] }}
          onSubmit={handleFilter}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Row>
                <Col span={3}>
                  <h3>TYPE</h3>
                  <Checkbox.Group options={listTypes?.map((value) => ({ label: value?.type ?? "", value: value?.type })) ?? []} onChange={(checkedValues) => setFieldValue("types", checkedValues)} defaultValue={oldValues?.types} />
                </Col>
                <Col span={6}>
                  <h3>TOPIC NAME</h3>
                  <Checkbox.Group options={listTopics?.topics?.map((value) => ({ label: value?.name ?? "", value: value?.id as number })) ?? []} onChange={(checkedValues) => { setFieldValue("topic_ids", checkedValues) }} defaultValue={oldValues?.topic_ids} />
                </Col>
                <Col span={6}>
                  <h3>TEST NAME</h3>
                  <Checkbox.Group options={listTests?.tests?.map((value) => ({ label: value?.name ?? "", value: value?.id as number })) ?? []} onChange={(checkedValues) => setFieldValue("test_ids", checkedValues)} defaultValue={oldValues?.test_ids} />
                </Col>
                <Col span={5}>
                  <h3>CREATED BY</h3>
                  <Checkbox.Group options={listUsers?.users?.map((value) => ({ label: value?.email ?? "", value: value?.id as number })) ?? []} onChange={(checkedValues) => setFieldValue("created_by_ids", checkedValues)} defaultValue={oldValues?.created_by_ids} />
                </Col>
                <Col span={4}>
                  <h3>STATUS</h3>
                  <Checkbox.Group options={listStatuses?.map((value) => ({ label: value?.type ?? "", value: value?.id as number })) ?? []} onChange={(checkedValues) => setFieldValue("statuses", checkedValues)} defaultValue={oldValues?.statuses} />
                </Col>
              </Row>
              <Row
                className="filter-controller"
                justify="end"
                align="middle"
              >
                <Col>
                  <Button type="text" onClick={onClose}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>

      </Drawer>

    </div>
  )
}

export default Questions;
