
/* Auth Routes */
export const AUTH = "/auth"
export const LOGIN = AUTH + "/login"
export const EMAIL = AUTH + "/email"
export const VERIFY = AUTH + "/verify"
export const RESET = AUTH + "/reset"

/* Home Route */
export const HOME = "/home"
export const TOPICS = "/topics"
export const CREATE_TOPIC=TOPICS+"/create"
export const VIEW_TOPIC=TOPICS+"/:topicId"
export const VIEW_PROPOSED_TOPIC=TOPICS+"/proposed-topic/:topicId"
export const EDIT_TOPIC=VIEW_TOPIC+"/edit"
export const EDIT_PROPOSED_TOPIC=VIEW_PROPOSED_TOPIC+"/edit"
export const CREATE_QUESTION=VIEW_TOPIC+"/createQuestion"
export const CREATE_TEST=VIEW_TOPIC+"/createTest"
export const VIEW_PENDING_QUESTION=VIEW_TOPIC+"/:questionId"
export const EDIT_PENDING_QUESTION=VIEW_PENDING_QUESTION+"/edit"
export const QUESTIONS = "/questions"
export const VIEW_QUESTION = QUESTIONS+"/:id"
export const EDIT_QUESTION=VIEW_QUESTION+"/edit"
export const HIRINGTEST = "/hiring-test"
export const VIEW_TEST=HIRINGTEST+"/:id"
export const CERTIFICATES = "/certificates"
export const VIEW_CERTFICATE_TEST=CERTIFICATES+"/:id"
export const VIEW_TEST_USER=VIEW_TEST+"/:userId"
export const VIEW_PUBLIC_TEST_USER=VIEW_CERTFICATE_TEST+"/:userId"
export const VIEW_PUBLIC_HIRING_TEST_USER=VIEW_TEST+"/public/:userId"
export const USERS = "/users"
export const USER = USERS+"/:id"
export const VIEW_SUBMITTED = USER+"/:id"
export const EDIT_SUBMITTED=VIEW_SUBMITTED+"/edit" 
export const VIEW_CERTIFICATE=USER+"/:id/certificate"
export const HELP = "/help"
export const VIEW_HELP = HELP+"/:id"
export const EDIT_HELP = VIEW_HELP+"/edit"
export const PROFILE="/profile"