
import {serializable, alias, object, list, primitive} from 'serializr';

export class NoOfTestsDeployed { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('max_no_of_attempts', primitive()))
	maxNoOfAttempts?: number;

	@serializable(alias('min_pass_percent', primitive()))
	minPassPercent?: number;

	@serializable(alias('difficulty_level', primitive()))
	difficultyLevel?: string;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('topic_id', primitive()))
	topicId?: string;

	@serializable(alias('number_of_questions', primitive()))
	numberOfQuestions?: number;

	@serializable(alias('expiry_date', primitive()))
	expiryDate?: string;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('is_deleted', primitive()))
	isDeleted?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

	@serializable(alias('created_by_id', primitive()))
	createdById?: number;

}

export class User { 

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('tests_completed', primitive()))
	testsCompleted?: number;

	@serializable(alias('questions_submitted', primitive()))
	questionsSubmitted?: number;

	@serializable(alias('tests_deployed', primitive()))
	testsDeployed?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('no_tests_attemped', primitive()))
	noTestsAttemped?: number;

	@serializable(alias('no_of_tests_deployes', list(object(NoOfTestsDeployed))))
	noOfTestsDeployes?: NoOfTestsDeployed[] = [];

	@serializable(alias('no_of_tests_deployed', primitive()))
	noOfTestsDeployed?: string;

	@serializable(alias('no_questions_submitted', primitive()))
	noQuestionsSubmitted?: number;

	@serializable(alias('no_of_certifications', primitive()))
	noOfCertifications?: number;

	@serializable(alias('no_suggested_topics', primitive()))
	noSuggestedTopics?: number;

}

export class Users{
    @serializable(alias("users", list(object(User))))
    users?: User[];
}

export class UserTest { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('no_of_questions', primitive()))
	noOfQuestions?: number;

	@serializable(alias('no_of_attempts', primitive()))
	noOfAttempts?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('certificate_status', primitive()))
	certificateStatus?: string;

}

export class UserTests{
    @serializable(alias("tests", list(object(UserTest))))
    userTest?: UserTest[];
}

export class Certificate { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('user_name', primitive()))
	userName?: string;

	@serializable(alias('passed_at', primitive()))
	passedAt?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('test_created_by', primitive()))
	testCreatedBy?: string;

}