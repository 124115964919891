import React, { useEffect, useState } from 'react'
import { Row, Switch, Table } from 'antd'; 
import UserService from '../../../../services/UserService/user.service';
import './submitted.scss'
import { useHistory, useRouteMatch } from 'react-router';
import * as appRoutes from './../../../../routes/routeConstants/appRoutes'
import QuestionService from '../../../../services/QuestionService/question.service';
import { Typography } from 'antd';
import {
    EllipsisOutlined,
} from "@ant-design/icons";

const { Paragraph, Text } = Typography;  

const AttemptedTests = () => {
    const {getUserSubmittedQuestions,loading,error,userQuestions}=UserService()
    const userId=window.location.pathname.split('/')[2];
    const [ellipsis, setEllipsis] = useState(true);
    const history=useHistory()
    const match = useRouteMatch()

    useEffect(() => {
      getUserSubmittedQuestions(userId)
    }, [])

    const columns = [
        {
          title: 'QUESTION ID',
          dataIndex: 'id',
        },
        {
          title: 'QUESTION TITLE',
          dataIndex: 'title',
          render: ((_: any, row: any) => (
            <Paragraph ellipsis={ellipsis ? { rows: 1, expandable: false, symbol: 'more' } : false}>{row?.title}</Paragraph>
          ))
        },
        {
          title: 'TYPE',
          dataIndex: 'type',
          render: ((_: any, row: any) => (
            <Paragraph ellipsis={ellipsis ? { rows: 1, expandable: false, symbol: 'more' } : false}>{row?.type}</Paragraph>
          ))
        },
        {
          title: 'TOPIC/TEST NAME',
          dataIndex: 'topicTest',
        },
        {
          title: 'DATE',
          dataIndex: 'createdAt',
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          render: ((_:any,row:any)=><p className={`status ${row?.status}`}>{row?.status}</p>)
        },
      ];

    return (
        <Row className="submitted__question-table">
            <Table loading={loading} onRow={(record, index) => ({
                onClick: (event) => {
                  history.push(`${match.url}/${record?.id}`, record?.id)
                }
            })}
                columns={columns} dataSource={userQuestions?.questions} size="middle" pagination={false} scroll={{ y: 380 }} />
        </Row>
    )
}

export default AttemptedTests
