import React from 'react'
import TopicForm from '../TopicForm'
import './create.scss'

const Create=()=> {
    return (
        <div className="app-wrapper">
            <h1>Create New Topic</h1>
                <TopicForm/>
        </div>
    )
}

export default Create
