import { Col, Row, Tabs } from 'antd';
import React, { useEffect } from 'react'
import UserService from '../../../services/UserService/user.service'
import {
    LeftOutlined
} from '@ant-design/icons'
import { useHistory } from 'react-router';
import './user.scss'
import DeployedTests from './DeployedTests';
import AttemptedTests from './AttemptedTests';
import SubmittedQuestions from './SubmittedQuestions';

const User = (props: any) => {
    const history = useHistory()
    const dummyUser = props?.location?.state
    const { getUser, user } = UserService()
    const userId = window.location.pathname.split('/')[2];
    const { TabPane } = Tabs;

    const changeTab = () => { }

    useEffect(() => {
        getUser(userId)
    }, [])

    return (
        <div className="app-wrapper user__profile">
            <Row className="user__profile-header">
                <LeftOutlined onClick={history.goBack} />
                <h2 onClick={history.goBack}>
                    {user?.email}
                </h2>
            </Row>
            <div className="user__profile-numbers">
                <Col>
                    <h1>{user?.noTestsAttemped}</h1>
                    <p>TEST ATTEMPTED</p>
                </Col>
                <Col>
                    <h1>{user?.noOfTestsDeployed}</h1>
                    <p>TEST DEPLOYED</p>
                </Col>
                <Col>
                    <h1>{user?.noQuestionsSubmitted}</h1>
                    <p>QUESTIONS SUBMITTED</p>
                </Col>
                <Col>
                    <h1>{user?.noOfCertifications}</h1>
                    <p>CERTIFICATIONS</p>
                </Col>
                <Col>
                    <h1>{user?.noSuggestedTopics}</h1>
                    <p>SUGGESTED TOPICS</p>
                </Col>
            </div>
            <div className="user__profile-tests">
                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Deployed Tests" key="1">
                        <DeployedTests/>
                    </TabPane>
                    <TabPane tab="Attempted Tests" key="2">
                        <AttemptedTests/>
                    </TabPane>
                    <TabPane tab="Submitted Questions" key="3">
                        <SubmittedQuestions/>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default User
