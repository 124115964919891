import React, { useEffect, useState } from 'react'
import { Row, Switch, Table } from 'antd'
import './managequestions.scss'
import QuestionService from '../../../../services/QuestionService/question.service'
import { QuestionTypes } from '../../../../enums/questionTypes'
import ParagraphEllipsis from '../../ParagraphEllipsis'

const ManageQuestions = (props: { type: String,topicId:string,render?:boolean }) => {
    const { getQuestions, questions,editTopicQuestion,loading,error } = QuestionService()
    const testId = window.location.pathname.split('/')[2]
    const module = window.location.pathname.split('/')[1]
    const [questionEdited, setQuestionEdited] = useState(false)

    function onChange(e: any,id:string) {
        editTopicQuestion(props?.topicId,id,{status:QuestionTypes.REJECTED},  ()=>{}, ()=>{}).then(()=>{
            setQuestionEdited(state=>!state)
        })
    }

    useEffect(() => {
        if(module==="hiring-test"&&props?.type) {
            getQuestions({ test_ids: [testId],statuses:[props.type] })
        }else if(module==="hiring-test"){
            getQuestions({ test_ids: [testId]})
        }
    }, [props?.type,props.render,questionEdited])
    
    useEffect(() => {
        if(module==="certificates"&&props?.type) {
            getQuestions({topic_ids:[props?.topicId],statuses:[props.type]})
        }else if(module==="certificates"){
            getQuestions({topic_ids:[props?.topicId]})
        }
    }, [props?.type,props.render,questionEdited])

    const columns = [
        {
            title: 'QUESTION ID',
            dataIndex: 'id',
        },
        {
            title: 'QUESTION',
            dataIndex: 'title',
            render: ((_: any, row: any) => (
                <ParagraphEllipsis text={row?.title}></ParagraphEllipsis>
            )),
        },
        {
            title: 'DATE',
            dataIndex: 'createdAt',
        },
        {
            title: 'CREATED BY',
            dataIndex: 'createdBy',
            render: ((_: any, row: any) =>
            (
                <div className="manage-question">
                    <p className={`status ${row?.status}`}>{row?.createdBy}</p>
                    {
                        (props.type === QuestionTypes.APPROVED) &&
                        <div className="switch-wrapper">
                            <Switch checked={true} defaultChecked={true} onChange={(e)=>onChange(e,row?.id)} />
                        </div>
                    }
                </div>
            ))
        },
    ];

    return (
        <Row className="manage__question-table">
            <Table onRow={(record, index) => ({
                onClick: (event) => {
                    // history.push(appRoutes.VIEW_TEST.replace(":id", `${record?.id}`), record?.id)
                }
            })}
                columns={columns} dataSource={questions?.questions} size="middle" pagination={false} scroll={{ y: 460 }} loading={loading}/>
        </Row>
    )
}

export default ManageQuestions
