import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import EmailForm from "./EmailForm";
import LoginForm from "../../views/Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import loginImg from "../../assets/images/login.png";
import verifyImg from "../../assets/images/verification.png";
import VerifyForm from "./VerifyForm";
import ResetForm from "./ResetForm";
import './auth.scss'

const authRouter = () => {
  const routes: RouterProps[] = [
    { exact: true, path: AppRoutes.LOGIN, component: LoginForm },
    { exact: true, path: AppRoutes.EMAIL, component: EmailForm },
    { exact: true, path: AppRoutes.VERIFY, component: VerifyForm },
    { exact: true, path: AppRoutes.RESET, component: ResetForm },
  ];

  return (
    <div className="auth-container">
      <div className="auth-container__header">
        <h3>Tiger Testing</h3>
        <h1>
          Why read 100's of resumes?? Test candidates first and interview only
          the best!
        </h1>
      </div>
      <div className="auth-container__footer">
        <div className="image__wrapper">
          <img src={window.location.pathname==="/auth/login"?loginImg:verifyImg} alt="" />
        </div>
        <div className="form__wrapper">
          <Switch>
            {routes.map(({ component, ...routerProps }) => (
              <Route {...routerProps} component={component as FC} />
            ))}
            <Redirect exact strict from={AppRoutes.AUTH} to={AppRoutes.LOGIN} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default authRouter;
