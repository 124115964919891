import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { useState } from "react";
import { useHistory } from "react-router";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Certificate, User, Users, UserTests } from "../../models/Users/users.model";
import { Questions } from "../../models/Questions/question.model";

const UserService = () => {
    const history = useHistory();

    const [users, setUsers] = useState<Users>();
    const [user, setUser] = useState<User>();
    const [userTests, setUserTests] = useState<UserTests>()
    const [userQuestions, setUserQuestions] = useState<Questions>()
    const [userCertificate, setuserCertificate] = useState<Certificate>()

    const [error, setError] = useState<Error>();

    const [loading, setLoading] = useState(false);

    const getUsers = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_USERS)
            .then((response) => {
                const _users = deserialize(Users, response.data);
                setUsers(_users);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUser = (id: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_USER.replace(":id", id))
            .then((response) => {
                const _user = deserialize(User, response.data["user"]);
                setUser(_user)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUserDeployedTests = (userId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_USER_DEPLOYED_TESTS.replace(":userId", userId))
            .then((response) => {
                const _usertest = deserialize(UserTests, response.data);
                setUserTests(_usertest)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUserAttemptedTests = (userId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_USER_ATTEMPTED_TESTS.replace(":userId", userId))
            .then((response) => {
                const _usertest = deserialize(UserTests, response.data);
                setUserTests(_usertest)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUserSubmittedQuestions = (userId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_USER_SUBMITTED_QUESTIONS.replace(":userId", userId))
            .then((response) => {
                const _userquestions = deserialize(Questions, response.data);
                setUserQuestions(_userquestions)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUserCertificate = (testId:string,userId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_USER_CERTIFICATE.replace(":testId", testId).replace(":userId", userId),{params:{"user_id":userId,"test_id":testId}})
            .then((response) => {
                const _usercertificate = deserialize(Certificate, response.data["test"]);
                setuserCertificate(_usercertificate)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        users,
        getUsers,
        error,
        loading,
        getUser,
        user,
        getUserDeployedTests,
        getUserAttemptedTests,
        getUserSubmittedQuestions,
        userTests,
        userQuestions,
        getUserCertificate,
        userCertificate
    };
};

export default UserService;
