import React from 'react'
import QuestionForm from '../../../../shared/components/QuestionForm'
import './createquestion.scss'

const CreateQuestion = () => {
        
    return (
        <div className="app-wrapper">
            <h1>New Question</h1>
            <QuestionForm/>
        </div>
    )
}

export default CreateQuestion
