import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import LocalStorage from "../../shared/localStorage";
import { CreateQuestionModel, Option, Question, Questions, Tests, Topics, Users } from "../../models/Questions/question.model";
import { convertJSONToFormData } from "../../shared/utils/formDataConvertor";

const QuestionService = () => {
    const [questions, setQuestions] = useState<Questions>();
    const [listTopics, setListTopics] = useState<Topics>()
    const [listTests, setListTests] = useState<Tests>()
    const [listUsers, setListUsers] = useState<Users>()
    const [question, setQuestion] = useState<Question>();
    const [newOption, setNewOption] = useState<any>();
    const [error, setError] = useState<Error>();
    const [questionError, setQuestionError] = useState<Error>();

    const [loading, setLoading] = useState(false);
    const [questionChangeLoading, setQestionChangeLoading] = useState(false);

    const getTopicsMeta=(search?:string)=>{
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.LIST_TOPICS,{params:{"search":search}})
            .then((response) => {
                const _topics = deserialize(Topics, response.data);
                setListTopics(_topics);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const getUsers=()=>{
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.LIST_USERS)
            .then((response) => {
                const _users = deserialize(Users, response.data);
                setListUsers(_users);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const getTests=()=>{
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.LIST_TESTS)
            .then((response) => {
                const _tests = deserialize(Tests, response.data);
                setListTests(_tests);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getTopicQuestions = (topicId:string,status?:string,search?:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TOPIC_QUESTIONS.replace(':topicId',topicId),{params:{"status":status,"search":search}})
            .then((response) => {
                const _questions = deserialize(Questions, response.data);
                setQuestions(_questions);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTopicQuestion = (topicId:string,questionId:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TOPIC_QUESTION.replace(':topicId',topicId).replace(':questionId',questionId))
            .then((response) => {
                const _questions = deserialize(Question, response.data["question"]);
                setQuestion(_questions);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editTopicQuestion = (topicId:string,questionId: any,question:FormData|{"status":String}|{"is_active":boolean}, onSuccess: Function, onError: Function) => {
        setQestionChangeLoading(true);
        return axiosInstance
            .put(ApiRoutes.GET_TOPIC_QUESTION.replace(":topicId", topicId).replace(":questionId",questionId),question)
            .then((response) => {
                onSuccess()
            })
            .catch((error) => {
                onError()
                setQuestionError(error);
            })
            .finally(() => {
                setQestionChangeLoading(false);
            });
    };

    const getQuestions = (options?:{
        topic_ids?: String[],
        statuses?: String[],
        test_ids?: String[],
        created_by_ids?: String[],
        types?: String[],
        questions_title_desc?:boolean,
        topic_test_name_desc?:boolean,
        created_by_desc?:boolean,
        date_desc?:boolean
      }) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_QUESTIONS,{params:options})
            .then((response) => {
                const _questions = deserialize(Questions, response.data);
                setQuestions(_questions);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getQuestion = (questionId: any) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_QUESTION.replace(":id", questionId))
            .then((response) => {
                const _question = deserialize(Question, response.data["question"]);
                setQuestion(_question);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editQuestion = (questionId: any,question:FormData|{"status":String}, onSuccess: Function, onError: Function) => {
        setQestionChangeLoading(true);
        return axiosInstance
            .put(ApiRoutes.GET_QUESTION.replace(":id", questionId),question)
            .then((response) => {
                onSuccess()
            })
            .catch((error) => {
                onError()
                setQuestionError(error);
            })
            .finally(() => {
                setQestionChangeLoading(false);
            });
        };
        
        const createQuestion = (question: FormData,topicId:any, onSuccess: Function, onError: Function) => {
            setQestionChangeLoading(true);
            return axiosInstance
            .post(ApiRoutes.CREATE_QUESTION.replace(":topicId",topicId),question,{headers:{'Content-Type':'multipart/form-data'}})
            .then((response) => {
                onSuccess()
            })
            .catch((error) => {
                onError()
                setQuestionError(error);
            })
            .finally(() => {
                setQestionChangeLoading(false);
            });
    };

    const createOption = (text: String,index:number,onSuccess:Function,onError:Function,image?:any) => {
        const _option= convertJSONToFormData({"option":{text:text,image:image}}) 
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.CREATE_OPTION,_option)
            .then((response) => {
                const newOption=deserialize(Option,response.data["option"])
                onSuccess(newOption,index)
                setNewOption(newOption)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editOption = (text: String) => {
        const _option={
            "option":{
                text,
                image:""
            }
        }
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.UPDATE_OPTION,_option)
            .then((response) => {
                const newOption=deserialize(Option,response.data["option"])
                setNewOption(newOption)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const updateOption = (option: FormData,optionId:any,index:number,onSuccess:Function,onError:Function) => {
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.UPDATE_OPTION.replace(":id",optionId),option,{headers:{'Content-Type':'multipart/form-data'}})
            .then((response) => {
                const newOption=deserialize(Option,response.data["option"])
                onSuccess(newOption,index)
                setNewOption(newOption)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return {
        questions,
        error,
        loading,
        getQuestions,
        getTopicQuestions,
        getTopicQuestion,
        editTopicQuestion,
        question,
        getQuestion,
        createQuestion,
        editQuestion,
        newOption,
        createOption,
        editOption,
        updateOption,
        getTests,
        getTopicsMeta,
        getUsers,
        listTests,
        listTopics,
        listUsers,
        questionError,
        questionChangeLoading
    };
};

export default QuestionService;
