import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { serialize } from "serializr";
import { Topic, Topics } from "../../models/Topics/topic.model";

const TopicService = () => {
    const history = useHistory();

    const [topics, setTopics] = useState<Topics>();
    const [topic, setTopic] = useState<Topic>();

    const [proposedTopics, setProposedTopics] = useState<Topics>();
    const [proposedTopic, setProposedTopic] = useState<Topic>();

    const [error, setError] = useState<Error>();

    const [loading, setLoading] = useState(false);

    const getTopics = (search?:string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TOPICS,{params:{"search":search}})
            .then((response) => {
                const _topics = deserialize(Topics, response.data);
                setTopics(_topics);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getProposedTopics = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_PROPOSED_TOPICS)
            .then((response) => {
                const _proposedtopics = deserialize(Topics, response.data);
                setProposedTopics(_proposedtopics);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTopic = (topicId: string) => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_TOPIC.replace(":id", topicId))
            .then((response) => {
                const _topic = deserialize(Topic, response.data["topic"]);
                setTopic(_topic);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createTopic=(topic: FormData)=>{
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.GET_TOPICS,topic,{headers:{'Content-Type':'multipart/form-data'}})
            .then((response) => {
                // const _topic = deserialize(Topic, response.data["topic"]);
                // setTopic(_topic);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const editTopic=(topic: any,topicId:string)=>{
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.GET_TOPIC.replace(":id",topicId),topic,{headers:{'Content-Type':'multipart/form-data'}})
            .then((response) => {
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const deleteTopic=(topicId: string)=>{
        setLoading(true);
        return axiosInstance
            .delete(ApiRoutes.GET_TOPIC.replace(":id",topicId))
            .then((response) => {
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return {
        topics,
        error,
        loading,
        getTopics,
        topic,
        getTopic,
        createTopic,
        editTopic,
        deleteTopic,
        proposedTopics,
        proposedTopic,
        getProposedTopics,
    };
};

export default TopicService;
