import React, { useEffect, useState } from 'react'
import { Col, Row, Table, Menu, Tabs } from 'antd'
import { useHistory, useRouteMatch } from 'react-router'
import {
    LeftOutlined
} from '@ant-design/icons'
import './test.scss'
import ManageQuestions from '../ManageQuestions'
import * as appRoutes from '../../../../routes/routeConstants/appRoutes'
import TestService from '../../../../services/TestService/test.service'
import { QuestionTypes } from '../../../../enums/questionTypes'

const Test = (props: any) => {
    const { getHiringTest, hiringTest, getHiringTestAttempts, hiringTestAttempts, error, loading } = TestService()
    const module = window.location.pathname.split('/')[1]
    const history = useHistory()
    const [render, setRender] = useState(false)
    const [testView, setTestView] = useState("dashboard")
    const handleTestView = (e: any) => {
        setTestView(e.key)
    }

    const { testId, testType } = props.location.state

    useEffect(() => {
        getHiringTest(testId)
        getHiringTestAttempts(testId)
    }, [])
    const { TabPane } = Tabs;
    const columns = [
        {
            title: 'USER ID',
            dataIndex: 'userId',
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
        },
        {
            title: 'DATE',
            dataIndex: 'startsAt',
        },
        {
            title: 'CORRECT/QUESTIONS',
            dataIndex: 'score',
            render: ((_: any, row: any) => (
                <>
                    <p className="report">{row?.score}/{row?.maxScore}</p>
                    <div className="">View Report</div>
                </>
            ))
        },
    ];

    let match = useRouteMatch();

    return (
        <div className="app-wrapper test__view">
            <Row className="test__view-banner">
                <div className="title__container">
                    <div className="title">
                        <LeftOutlined onClick={history.goBack} />
                        <h1 onClick={history.goBack}>
                            {hiringTest?.name}
                        </h1>
                    </div>
                    <div className="info">
                        <p>Expiry date: {hiringTest?.expiryDate}</p>
                        <p>Time per question: {hiringTest?.timePerQuestion} sec</p>
                    </div>
                </div>
                <div className="footer">
                    <Menu
                        onClick={handleTestView}
                        selectedKeys={[testView]}
                        mode="horizontal"
                        className=""
                    >
                        <Menu.Item key="dashboard">
                            <div className="">Dashboard</div>
                        </Menu.Item>
                        <Menu.Item key="ManageQuestions">
                            <div className="">Manage - Questions</div>
                        </Menu.Item>
                    </Menu>
                </div>
            </Row>
            {
                testView === "dashboard" ?
                    <>
                        <div className="test__view-numbers">
                            <Col>
                                <h1>{hiringTest?.noOfAttempts}</h1>
                                <p>ATTEMPTS</p>
                            </Col>
                            <Col>
                                <h1>{hiringTest?.noOfTestsCompleted}</h1>
                                <p>TEST COMPLETED</p>
                            </Col>
                            <Col>
                                <h1>{hiringTest?.usersScoreFiftyPercentHigher}</h1>
                                <p>USERS &gt; 50%</p>
                            </Col>
                            <Col>
                                <h1>{hiringTest?.usersScoreFiftyPercentLower}</h1>
                                <p>USERS &lt; 50%</p>
                            </Col>
                        </div>
                        <div className="test__view-table">
                            <Table loading={loading} onRow={(record, index) => ({
                                // onClick: (event) => {
                                //     hiringTest?.testType=="custom" ?history.push(`${match.url}/${record?.id}`,{"userId":record?.id,"type":hiringTest?.testType}):history.push(`${match.url}/public/${record?.id}`,{"userId":record?.id,type:"public"})
                                // }
                                onClick: (event) => {
                                    // CHECK HIRING TEST TYPE
                                    if (module === "hiring-test") {
                                        testType==="certificate" ?
                                            history.push(`${match.url}/public/${record?.id}`, { "userId": record?.id, "type": "certificate" }) :
                                            history.push(`${match.url}/public/${record?.id}`, { "userId": record?.id, "type": "custom" })
                                    } else {
                                        history.push(`${match.url}/${record?.id}`, { "userId": record?.id,  "type": "certificate" })
                                    }
                                }
                            })}
                                columns={columns} dataSource={hiringTestAttempts?.attempts} size="middle" pagination={false} scroll={{ y: 358 }} />
                        </div>
                    </> :
                    <>
                        <div className="test__view-menubar">
                            <Tabs defaultActiveKey="1" onChange={() => { }} onTabClick={(key, event) => {
                                setRender(state => !state)
                            }}>
                                <TabPane tab="All Questions" key="1">
                                    <ManageQuestions topicId={`${hiringTest?.topicId}` || "1"} type="" render={render}/>
                                </TabPane>
                                <TabPane tab="Approved" key="2">
                                    <ManageQuestions topicId={`${hiringTest?.topicId}` || "1"} type={QuestionTypes.APPROVED} render={render}/>
                                </TabPane>
                                <TabPane tab="Pending Approval" key="3">
                                    <ManageQuestions topicId={`${hiringTest?.topicId}` || "1"} type={QuestionTypes.PENDING_APPROVAL} render={render}/>
                                </TabPane>
                                <TabPane tab="Rejected" key="4">
                                    <ManageQuestions topicId={`${hiringTest?.topicId}` || "1"} type={QuestionTypes.REJECTED} render={render}/>
                                </TabPane>
                            </Tabs>
                        </div>
                    </>
            }
        </div>
    )
}

export default Test
