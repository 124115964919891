import React, { useEffect } from 'react'
import { Row, Switch, Table } from 'antd';
import UserService from '../../../../services/UserService/user.service';
import './attempted.scss'
import { useHistory,useRouteMatch } from 'react-router';
import * as appRoutes from './../../../../routes/routeConstants/appRoutes'
import ParagraphEllipsis from '../../../../shared/components/ParagraphEllipsis';

const AttemptedTests = () => {
    const { getUserAttemptedTests, userTests, loading, error } = UserService()
    const userId = window.location.pathname.split('/')[2];
    const history=useHistory()
    const match = useRouteMatch()

    useEffect(() => {
        getUserAttemptedTests(userId)
    }, [])

    const columns = [
        {
            title: 'TEST NAME',
            dataIndex: 'name',
            render: ((_: any, row: any) => <ParagraphEllipsis text={row?.name}></ParagraphEllipsis>)
        },
        {
            title: 'TYPE',
            dataIndex: 'testType',
        },
        {
            title: 'NO OF ATTEMPTS',
            dataIndex: 'noOfAttempts',
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: ((_: any, row: any) => <p className={`status ${row?.status}`}>{row?.status}</p>)
        }
    ];

    return (
        <Row className="attempted__test-table">
            <Table loading={loading} onRow={(record, index) => ({
                onClick: (event) => {
                    record?.certificateStatus==="eligible"&&
                    history.push(`${match.url}/${record?.id}/certificate`, record)
                }
            })}
                columns={columns} dataSource={userTests?.userTest} size="middle" pagination={false} scroll={{ y: 380 }} />
        </Row>
    )
}

export default AttemptedTests
