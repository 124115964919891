import React, { useEffect } from "react";
import { Popover, Row, Table } from "antd";
import TestService from "../../../services/TestService/test.service";
import './tests.scss';
import { useHistory } from "react-router";
import * as appRoutes from './../../../routes/routeConstants/appRoutes'
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import ParagraphEllipsis from "../ParagraphEllipsis";
import { TestTypes } from "../../../enums/testTypes";

const Tests = () => {
  const { getHiringTests, hiringTests, certificateTests, getCertificateTests, deleteTest, error, loading } = TestService()
  const module = window.location.pathname.split('/')[1]
  const history = useHistory()

  const getPopoverContent = (content: String) => {
    return <p>{content}</p>
  }

  const copyToClipboard = (text: string) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  const deleteATest = (testId: string) => {
    deleteTest(testId).then(() => {
      module === "hiring-test" ?
        getHiringTests() :
        getCertificateTests()
    })
    if (error) {
      Notification({
        message: "Error",
        description: `Error deleting the test`,
        type: NotificationTypes.ERROR,
      });
    } else {
      Notification({
        message: `Test deleted successfully`,
        description: "",
        type: NotificationTypes.SUCCESS,
      });
    }
  }

  useEffect(() => {
    module === "hiring-test" ?
      getHiringTests() :
      getCertificateTests()
  }, [module])

  const columns = module === "hiring-test" ? [
    {
      title: 'TEST NAME',
      dataIndex: 'name',
      render: ((_: any, row: any) => <ParagraphEllipsis text={row?.name}></ParagraphEllipsis>),
      onFilter: (value: any, record: any) => record?.name?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name)
    },
    {
      title: 'CREATED BY',
      dataIndex: 'createdBy',
      render: ((_: any, row: any) => <ParagraphEllipsis text={row?.createdBy}></ParagraphEllipsis>),
      onFilter: (value: any, record: any) => record?.createdBy?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.createdBy?.localeCompare(b?.createdBy)
    },
    {
      title: 'TEST TYPE',
      dataIndex: 'testType',
      render: ((_: any, row: any) => <ParagraphEllipsis text={row?.testType === TestTypes.HIRING_CERTIFICATE ? "certificate" : "custom"}>{row?.testType === TestTypes.HIRING_CERTIFICATE ? "certificate" : "custom"}</ParagraphEllipsis>),
      onFilter: (value: any, record: any) => record?.testType?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.testType?.localeCompare(b?.testType)
    },
    {
      title: 'NO OF ATTEMPTS',
      dataIndex: 'noOfAttempts',
      sorter: (a: any, b: any) => a?.noOfAttempts - b?.noOfAttempts,
    },
    {
      title: 'NO OF USERS',
      dataIndex: 'noOfUsers',
      sorter: (a: any, b: any) => a?.noOfUsers - b?.noOfUsers,
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      onFilter: (value: any, record: any) => record?.status?.indexOf(value) === 0,
      sorter: (a: any, b: any) => a?.status?.localeCompare(b?.status),
      render: ((_: any, row: any) =>
      (
        <>
          <p className={`status ${row?.status}`}>{row?.status?.replace('_', " ")}</p>
          <div className="">
            <Popover placement="top" content={getPopoverContent("Link copied!")} trigger="hover">
              {
                row?.status=="active" &&
                <i className="icon-copy-link"
                  onClick={(e) => {
                    e.stopPropagation()
                    // navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${row?.id}`);
                    copyToClipboard(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${row?.encryptedTestId}`);
                  }}
                ></i>
              }
            </Popover>
            <Popover placement="top" content={getPopoverContent("Edit")} trigger="hover">
              <i className="icon-edit"></i>
            </Popover>
            <Popover placement="top" content={getPopoverContent("Delete")} trigger="hover">
              <i className="icon-delete" onClick={(e) => {
                e.stopPropagation()
                deleteATest(row?.id)
              }}></i>
            </Popover>
          </div>
        </>
      )),
      className: "actions"
    },
  ] :
    [
      {
        title: 'TEST NAME',
        dataIndex: 'name',
        render: ((_: any, row: any) => <ParagraphEllipsis text={row?.name}></ParagraphEllipsis>),
        onFilter: (value: any, record: any) => record?.name?.indexOf(value) === 0,
        sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name)
      },
      {
        title: 'TOPIC',
        dataIndex: 'topicName',
        render: ((_: any, row: any) => <ParagraphEllipsis text={row?.topicName}></ParagraphEllipsis>),
        onFilter: (value: any, record: any) => record?.topicName?.indexOf(value) === 0,
        sorter: (a: any, b: any) => a?.topicName?.localeCompare(b?.topicName)
      },
      {
        title: 'TEST TYPE',
        dataIndex: 'testType',
        onFilter: (value: any, record: any) => record?.testType?.indexOf(value) === 0,
        sorter: (a: any, b: any) => a?.testType?.localeCompare(b?.testType)
      },
      {
        title: 'NO OF ATTEMPTS',
        dataIndex: 'noOfAttempts',
        sorter: (a: any, b: any) => a?.noOfAttempts - b?.noOfAttempts,
      },
      {
        title: 'NO OF USERS',
        dataIndex: 'noOfUsers',
        sorter: (a: any, b: any) => a?.noOfUsers - b?.noOfUsers,
      },
      {
        title: 'NO OF CERTIFICATES',
        dataIndex: 'noOfCertificates',
        sorter: (a: any, b: any) => a?.noOfCertificates - b?.noOfCertificates,
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        onFilter: (value: any, record: any) => record?.status?.indexOf(value) === 0,
        sorter: (a: any, b: any) => a?.status?.localeCompare(b?.status),
        render: ((_: any, row: any) =>
        (
          <>
            <p className={`status ${row?.status}`}>{row?.status?.replace('_', " ")}</p>
            <div className="">
              <Popover placement="top" content={getPopoverContent("Link copied!")} trigger="hover">
                <i className="icon-copy-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/take-a-hiringtest/instructions/${row?.encryptedTestId}`);
                  }}
                ></i>
              </Popover>
              <Popover placement="top" content={getPopoverContent("Edit")} trigger="hover">
                <i className="icon-edit"></i>
              </Popover>
              <Popover placement="top" content={getPopoverContent("Delete")} trigger="hover">
                <i className="icon-delete" onClick={(e) => {
                  e.stopPropagation()
                  deleteATest(row?.id)
                }}></i>
              </Popover>
            </div>
          </>))
      }
    ];

  return (
    <div className="hiring-tests__list app-wrapper">
      <div className="hiring-tests__list-header">
        <div className="gutter-row">
          <h1>{module === "hiring-test" ? hiringTests?.noOfTestsDeployed : certificateTests?.noOfCertsDeployed}</h1>
          <p>NO. OF  {module === "certificates" ? "CERTS" : "TESTS"} DEPLOYED</p>
        </div>
        <div className="gutter-row">
          <h1>{module === "hiring-test" ? hiringTests?.activeTests : certificateTests?.activeCerts}</h1>
          <p>ACTIVE {module === "certificates" ? "CERTS" : "TESTS"}</p>
        </div>
        <div className="gutter-row">
          <h1>{module === "hiring-test" ? hiringTests?.testsNotDeployed : certificateTests?.certsNotDeployed}</h1>
          <p>{module === "certificates" ? "CERTS" : "TESTS"} NOT DEPLOYED</p>
        </div>
        <div className="gutter-row">
          <h1>{module === "hiring-test" ? hiringTests?.noOfExpiredTests : certificateTests?.noOfDeletedCerts}</h1>
          <p>NO OF {module === "certificates" ? "DELETED CERTS" : "EXPIRED TESTS"}</p>
        </div>
      </div>
      <Row className="hiring-tests__list-table">
        <Table
          loading={loading}
          onRow={(record, index) => ({
            onClick: (event) => {
              module === "hiring-test" ?
                history.push(appRoutes.VIEW_TEST.replace(":id", `${record?.id}`), {
                  testId: record?.id,
                  testType: record?.testType === TestTypes.HIRING_CERTIFICATE ? "certificate" : "custom"
                }) :
                history.push(appRoutes.VIEW_CERTFICATE_TEST.replace(":id", `${record?.id}`), {
                  testId: record?.id,
                  testType: "certificate"
                })
            }
          })}
          // columns={columns} dataSource={module==="hirin-test"?hiringTests?.tests:certificateTests?.tests} size="middle" pagination={false} scroll={{ y: 550 }} />
          columns={columns}
          dataSource={module === "hiring-test" ? hiringTests?.tests : certificateTests?.tests}
          size="middle"
          pagination={false} scroll={{ y: 490 }} />
      </Row>
    </div>
  )
}

export default Tests;
