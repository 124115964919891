import React, { useEffect } from "react";
import { Row } from "antd";
import "./help.scss"
import tutorialImage from './../../assets/images/certification.png'
import { useHistory, withRouter, useRouteMatch } from "react-router";
import FaqService from './../../services/FaqService/faq.service'

const Help = (props: any) => {
    const { getHelpTabs, loading, error, helpTabs } = FaqService()
    const history = useHistory()
    let match = useRouteMatch();

    useEffect(() => {
        getHelpTabs()
    }, [])

    return (
        <div className="app-wrapper tutorial__view">
            <Row className="tutorial__view-header">
                <h1>Tutorial Questions</h1>
            </Row>
            <div className="tutorial__view__questions">
                {
                    helpTabs?.helpTabs?.map((question) => (
                        <div className="tutorial__view__questions-question" onClick={() => history.push(`${match.url}/${question?.id}`, question)}>
                            <div className="" onClick={(e) => {
                                e.stopPropagation()
                                history.push(`${match.url}/${question?.id}/edit`, question)
                            }}>
                                <i className="icon-edit-2"></i>
                            </div>
                            <i className="icon-placeholder" ></i>
                            <h3>{question?.title}</h3>
                            <p>{question?.description}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Help;
