import React, { useCallback, useEffect, useState } from 'react'
import './allquestions.scss'
import AuthContainer from '../../../../store/container/AuthContainer'
import { Table, Input } from 'antd';
import { Form, Formik } from "formik";
import {
    SearchOutlined,
    CheckCircleTwoTone
} from '@ant-design/icons'
import QuestionService from '../../../../services/QuestionService/question.service';
import { debounce } from "lodash";
import { Typography } from 'antd';
import {
    EllipsisOutlined,
} from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const AllQuestions = (props: any) => {
    const { error, loading, getTopicQuestions, getTopicQuestion, questions, question, getQuestion } = QuestionService();
    const topicId = window.location.pathname.split('/')[2]

    const [ellipsis, setEllipsis] = useState(true);

    useEffect(() => {
        getTopicQuestions(topicId)
    }, [props.render])

    useEffect(() => {
        if (questions?.questions?.length) {
            getTopicQuestion(topicId, `${questions?.questions?.[0].id}`)
        }
    }, [questions])

    const columns = [
        {
            title: '',
            dataIndex: 'id',
            render: ((_: any, row: any) => <p className={`${question?.id == row?.id ? "bold" : ""}`}>{row?.id}.{" "}</p>)
        },
        {
            title: '',
            dataIndex: 'title',
            render: ((_: any, row: any) => (
                <Paragraph className={`${question?.id == row?.id ? "bold" : ""}`} ellipsis={ellipsis ? { rows: 1, expandable: false, symbol: 'more' } : false}>{row?.title}</Paragraph>
                // <p className={`${question?.id == row?.id ? "bold" : ""}`}>{row.title}</p>
            ))
        },
    ];

    const getSearchQuestions = (query: string) => {
        getTopicQuestions(topicId, undefined, query)
    }

    const handleSearch = useCallback(debounce(q => getSearchQuestions(q), 1000, {}), [])

    const onChange = (e: any) => handleSearch(e?.target?.value)

    const optionImage = (element:any) => element?.imageUrl !== null
    const isGrid=question?.options?.some(optionImage)

    return (
        <div className="all__questions">
            <div className="questions__list">
                <div className="search-box">
                    <Input placeholder="Search" name="search" type="text" onChange={onChange} addonAfter={<SearchOutlined />} />
                    <Table
                        onRow={(record, index) => ({
                            onClick: (event) => getTopicQuestion(topicId, `${record?.id}`)
                        })}
                        columns={columns}
                        dataSource={questions?.questions}
                        size="middle"
                        pagination={false}
                        scroll={{ y: 530 }}
                    />
                </div>
            </div>
            <div className="question__view">
                {
                    question ?
                        <>
                            <div className="question__view-question">
                                <p>Question</p>
                                <p className="question-text">{question?.title}</p>
                                {
                                    question?.imageUrl &&
                                    <img src={question?.imageUrl} alt="" />
                                }
                            </div>
                            <div className="question__view-answer">
                                <p>Answers</p>
                                <div className={isGrid?"answers_grid":""}>
                                    {
                                        question?.options?.map((answer) => (
                                            // answer?.imageUrl ?
                                                <div className="answer_item">
                                                    <h3>{answer?.text}</h3>
                                                    {
                                                        answer?.imageUrl &&
                                                        <img src={answer?.imageUrl} alt="" />
                                                    }
                                                </div>
                                                // :
                                                // <h3>{answer?.text}</h3>
                                        ))
                                    }
                                </div>
                                <p style={{ marginTop: "15px" }}>Correct Answer</p>
                                {question?.correctAnswer?.text &&
                                    <h3>
                                        <CheckCircleTwoTone twoToneColor="#40CE2A" />
                                        <span>{question?.correctAnswer?.text}</span>
                                    </h3>
                                }
                                <img className="correct_answer_image" src={question?.correctAnswer?.imageUrl} alt="" />
                            </div>
                        </>
                        : null
                }
            </div>
        </div>
    )
}

export default AllQuestions
