import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "../Validations"
import { Button } from "antd";
import { withRouter, useHistory } from "react-router-dom";
import { EMAIL, HOME } from "../../../routes/routeConstants/appRoutes";
import AuthService from "../../../services/AuthService/auth.service";

interface User {
    email: string,
    password: string
}

const initialValue = {
    email: "",
    password: ""
}

const LoginForm = () => {
    const history = useHistory()

    const { user, error, loading, loginUser } = AuthService();

    const onSubmit = (user: User) => {
        loginUser(user);
    }

    return (
        <Formik
            initialValues={initialValue}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form className="login__form">
                <h2 className="form-header login">Sign in</h2>
                <InputField type="email" name="email" placeholder="Email" />
                <InputField type="password" name="password" placeholder="Password" />
                <Button type="text" onClick={() => history.push(EMAIL)}>Forgot Password?</Button>
                <Button type="primary" htmlType="submit">Sign in</Button>
            </Form>
        </Formik>
    )
}

export default withRouter(LoginForm);