import { serializable, alias, object, list, primitive } from 'serializr';

export class Test {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('max_no_of_attempts', primitive()))
	maxNoOfAttempts?: number;

	@serializable(alias('min_pass_percent', primitive()))
	minPassPercent?: number;

	@serializable(alias('difficulty_level', primitive()))
	difficultyLevel?: string;

	@serializable(alias('number_of_questions', primitive()))
	numberOfQuestions?: number;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;
}

export class Tests {
	@serializable(alias("tests", list(object(Test))))
	tests?: Test[] = [];
}

export class CreateTest {

	@serializable(alias('topicName', primitive()))
	topicName?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('max_no_of_attempts', primitive()))
	maxNoOfAttempts?: number;

	@serializable(alias('min_pass_percent', primitive()))
	minPassPercent?: number;

	@serializable(alias('difficulty_level', primitive()))
	difficultyLevel?: string;

	@serializable(alias('number_of_questions', primitive()))
	numberOfQuestions?: number;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('expiry_days', primitive()))
	expiryDays?: number;

}
export class HiringTest {

	@serializable(alias('encrypted_test_id', primitive()))
	encryptedTestId?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('created_by', primitive()))
	createdBy?: string;

	@serializable(alias('topic_name', primitive()))
	topicName?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('no_of_attempts', primitive()))
	noOfAttempts?: number;

	@serializable(alias('no_of_users', primitive()))
	noOfUsers?: number;

	@serializable(alias('no_of_certificates', primitive()))
	noOfCertificates?: number;

	@serializable(alias('status', primitive()))
	status?: string;

}

export class HiringTests {

	@serializable(alias('no_of_tests_deployed', primitive()))
	noOfTestsDeployed?: number;

	@serializable(alias('active_tests', primitive()))
	activeTests?: number;

	@serializable(alias('tests_not_deployed', primitive()))
	testsNotDeployed?: number;

	@serializable(alias('no_of_expired_tests', primitive()))
	noOfExpiredTests?: number;

	@serializable(alias('no_of_certs_deployed', primitive()))
	noOfCertsDeployed?: number;

	@serializable(alias('active_certs', primitive()))
	activeCerts?: number;

	@serializable(alias('certs_not_deployed', primitive()))
	certsNotDeployed?: number;

	@serializable(alias('no_of_deleted_certs', primitive()))
	noOfDeletedCerts?: number;

	@serializable(alias('tests', list(object(HiringTest))))
	tests?: HiringTest[] = [];

}



	export class Attempt { 

		@serializable(alias('id', primitive()))
		id?: number;
	
		@serializable(alias('user_id', primitive()))
		userId?: number;
	
		@serializable(alias('email', primitive()))
		email?: string;
	
		@serializable(alias('starts_at', primitive()))
		startsAt?: string;
	
		@serializable(alias('score', primitive()))
		score?: number;
	
		@serializable(alias('max_score', primitive()))
		maxScore?: number;
	
	}

export class ViewHiringTest {

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('expiry_date', primitive()))
	expiryDate?: string;

	@serializable(alias('test_type', primitive()))
	testType?: string;

	@serializable(alias('time_per_question', primitive()))
	timePerQuestion?: number;

	@serializable(alias('no_of_attempts', primitive()))
	noOfAttempts?: number;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('topic_id', primitive()))
	topicId?: number;

	@serializable(alias('no_of_tests_completed', primitive()))
	noOfTestsCompleted?: number;

	@serializable(alias('users_score_fifty_percent_higher', primitive()))
	usersScoreFiftyPercentHigher?: number;

	@serializable(alias('users_score_fifty_percent_lower', primitive()))
	usersScoreFiftyPercentLower?: number;

}

export class ViewHiringTestAttempts {

	@serializable(alias('attempts', list(object(Attempt))))
	attempts?: Attempt[] = [];

}
export class Question {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('title', primitive()))
	title?: string;

	@serializable(alias('answer', primitive()))
	answer?: string;

	@serializable(alias('time_taken', primitive()))
	timeTaken?: number;

	@serializable(alias('status', primitive()))
	status?: string;

}

export class UserReport {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('user_id', primitive()))
	userId?: number;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('answer', primitive()))
	answer?: string;

	@serializable(alias('time_taken', primitive()))
	timeTaken?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('question_id', primitive()))
	questionId?: number;

	@serializable(alias('question', primitive()))
	question?: string;

	@serializable(alias('tags', list(primitive())))
	tags=[];

	@serializable(alias('questions', list(object(Question))))
	questions?: Question[] = [];

}

export class UserReports {

	@serializable(alias('user_answers', list(object(UserReport))))
	user_answers?: UserReport[] = [];

}