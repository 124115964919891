import React, { useEffect } from 'react'
import { Row, Table } from 'antd'
import { useHistory, useRouteMatch } from 'react-router'
import {
  LeftOutlined
} from '@ant-design/icons'
import './testuser.scss'
import TestService from '../../../../../services/TestService/test.service'
import ParagraphEllipsis from '../../../ParagraphEllipsis'

const TestUser = (props: any) => {
  const { getUserReport, userReports, loading, error } = TestService()
  const {userId,type} = props.location.state
  const testId = window.location.pathname.split('/')[2]
  const history = useHistory()

  useEffect(() => {
    getUserReport(testId, userId)
  }, [])

  const columns = 
  type==="certificate"? 
  [
    {
      title: 'QUESTION ID',
      dataIndex: 'id',
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      render: ((_:any,row:any)=>(
        <Row gutter={[10,10]}>
          {
              <p className={`tag`}>{row?.tags?.toString()?.replaceAll(","," ")}</p>
          }
        </Row>
      ))
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: ((_:any,row:any)=><p className={`status ${row?.status}`}>{row?.status}</p>)
    },
  ]:
  [
    {
      title: 'QUESTION',
      dataIndex: 'question',
      // render: ((_: any, row: any) => <span>{row.id}. {" "}{row.question}</span>),
      render: ((_: any, row: any) => (
        <ParagraphEllipsis text={`${row?.id}. ${row?.question}`}></ParagraphEllipsis>
    )),
    },
    {
      title: 'ANSWER',
      dataIndex: 'answer',
      render: ((_: any, row: any) => <span>{row?.answer||"_"}</span>)
    },
    {
      title: 'TIME PER QUESTION',
      dataIndex: 'timeTaken',
      render: ((_: any, row: any) => <span>{row?.timeTaken||0}{" "}{`sec`}</span>)
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: ((_: any, row: any) => <p className={`status ${row?.status}`}>{row?.status?.replace('_'," ")}</p>)
    },
  ]
  
  return (
    <div className="app-wrapper test__user">
      <Row className="test__user-header">
        <LeftOutlined onClick={() => history.goBack()} />
        <h1>{userReports?.user_answers?.[0]?.email}</h1>
      </Row>
      <Row className={`test__user-table ${type}`}>
        <Table
          loading={loading}
          onRow={(record, index) => ({
            onClick: (event) => {
              // history.push(`${match.url}/${record?.id}`,record?.id)
            }
          })}
          columns={columns}
          dataSource={userReports?.user_answers}
          size="middle"
          pagination={false}
          scroll={{ y: 600 }} />
      </Row>
    </div>
  )
}

export default TestUser
