import React from 'react'
import Tests from '../../shared/components/Tests'

const HiringTest = () => {
    return (
        <Tests/>
    )
}

export default HiringTest
