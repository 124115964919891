import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Menu,
  Button,
  Tabs,
  Table,
  Input
} from "antd"
import {
  SearchOutlined
} from '@ant-design/icons'
import './topics.scss'
import TopicService from "../../services/TopicService/topic.service";
import TopicsList from "../../shared/components/TopicsList";
import InputField from "../../shared/components/InputField";
import { useHistory, useRouteMatch, withRouter } from "react-router";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import { Form, Formik } from "formik";
import { debounce } from "lodash";
import QuestionService from "../../services/QuestionService/question.service";

const { TabPane } = Tabs;

const Topics = (props: any) => {
  let match = useRouteMatch();
  const history = useHistory()
  const { error, loading, getTopics, topics, getProposedTopics, proposedTopics } = TopicService();
  const { getTopicsMeta, listTopics } = QuestionService();
  const [topic, setTopic] = useState("all-topics");
  const [tabKey, setTabKey] = useState("1")

  const handleTopics = (e: any) => {
    setTopic(e.key);
    (window as any).topicType = e.key
  }

  useEffect(() => {
    getTopics();
    getProposedTopics();
    getTopicsMeta();
    (window as any).topicType = "all-topics"
  }, [])

  const columns = [
    {
      title: '',
      dataIndex: 'name',
    },
  ];

  const getSearchTopics = (query: string) => {
    // PASS SEARCH TEAT TO META
    getTopicsMeta(query)
  }

  const handleSearch = useCallback(debounce(q => getSearchTopics(q), 1000, {}), [])

  const onChange = (e: any) => handleSearch(e?.target?.value)

  return (
    <div className="topics-list app-wrapper">
      <Row className="topics-list__menubar" align="top">
        <div className="topics-menubar">
          <Tabs defaultActiveKey="1" onChange={() => { }} onTabClick={(key, event) => {
            setTabKey(key)
            key=="1"?getTopics():getProposedTopics()
            // setRender(state => !state)
          }}>
            <TabPane tab="All Topics" key="1">

            </TabPane>
            <TabPane tab="Proposed Topics" key="2">

            </TabPane>
          </Tabs>
        </div>
        <div className="create-topic">
          {
            topic === "all-topics" ?
              <Button type="primary" onClick={() => history.push(appRoutes.CREATE_TOPIC)}>Create Topic</Button>
              : null
          }
        </div>
      </Row>
      <Row className="topics-list__container" gutter={20}>
        {
          tabKey === "1" &&
          <>
            <Col span={6} className="topics-list__search-bar">
              <div className="search-box">
                <Input placeholder="Search" name="search" type="text" addonAfter={<SearchOutlined />} onChange={onChange} />
                <div className="list-languages">
                  <Table
                    onRow={(record, index) => ({
                      onClick: (event) => {
                        history.push(`${match.url}/${record?.id}`)
                      }
                    })}
                    columns={columns}
                    dataSource={listTopics?.topics}
                    size="middle"
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 570 }} />
                </div>
              </div>
            </Col>
            <Col span={18} className="topics-list__items">
              <TopicsList topics={topics?.topics} cols={8} />
            </Col>
          </>
        }
        {
          tabKey === "2" &&
          <>
            <Col span={24} className="topics-list__items">
              <TopicsList topics={proposedTopics?.topics} cols={6} />
            </Col>
          </>
        }
      </Row>
    </div>
  )
}

export default Topics;
