import React from 'react'
import QuestionForm from '../QuestionForm'

const EditQuestion = (props:any) => {
    return (
        <div className="app-wrapper">
            <h1>Edit Question</h1>
            <QuestionForm question={props?.location?.state}/>
        </div>
    )
}

export default EditQuestion
