import React, { useEffect } from 'react'
import './alltests.scss'
import AuthContainer from '../../../../store/container/AuthContainer'
import TestService from '../../../../services/TestService/test.service';

const AllQuestions = () => {
    const { error, loading, tests, getTests } = TestService();
    const topicId = window.location.pathname.split('/')[2]

    useEffect(() => {
        getTests(topicId)
    }, [])

    return (
        <div className="all__tests">
            {tests?.tests?.map((test) => (
                <div className="all__tests-card">
                    <h2>{test?.name}
                        {/* {
                            test?.testType==="certificate" &&
                            <i className="icon-certificates"></i>
                        } */}
                    </h2>
                    <p>{test?.numberOfQuestions}{" "}Questions</p>
                </div>
            ))}
            {/* <div className="all__tests-card">
                <h2>{"Angular"}
                    <i className="icon-certificates"></i>
                </h2>
                <p>{22}{" "}Questions</p>
            </div>
            <div className="all__tests-card">
                <h2>{"React"}
                    <i className="icon-certificates"></i>
                </h2>
                <p>{21}{" "}Questions</p>
            </div>
            <div className="all__tests-card">
                <h2>{"Angular"}
                    <i className="icon-certificates"></i>
                </h2>
                <p>{22}{" "}Questions</p>
            </div>
            <div className="all__tests-card">
                <h2>{"React"}
                    <i className="icon-certificates"></i>
                </h2>
                <p>{21}{" "}Questions</p>
            </div> */}
        </div>
    )
}

export default AuthContainer(AllQuestions)
