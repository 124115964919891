import React, { useEffect } from "react";
import { Menu, Popover, Button } from 'antd';
import "./navbar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import LocalStorage from "../../localStorage";
import { useHistory, withRouter } from "react-router";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import logo from './../../../assets/images/tiger testing-06.svg'
import {
  EllipsisOutlined,
} from "@ant-design/icons";
import UserService from "../../../services/AuthService/auth.service";
import ProfileService from "../../../services/ProfileService/profile.service";


const Navbar = (props: any) => {
  const {signOut, error, loading} = UserService()
  const {getProfile,profile} = ProfileService()
  const history = useHistory()
  const { pathname } = history.location;
  const { authenticated, user } = props;
  const handleLogout = () => {
    signOut()
    Notification({ message: "Logout", description: "user loggedout successfully", type: NotificationTypes.SUCCESS })
    LocalStorage.removeItem("tiger-token")
    LocalStorage.removeItem("tiger-access-token")
    return props.setUnAuthenticated();
  };

  useEffect(() => {
    getProfile()
  }, [])

  const handleClick = (e: any) => {
    history.push(e.key);
  };
  const popoverContent = <ul className="popover-list">
    <li onClick={() => history.push(appRoutes.PROFILE)}><i className="icon-my-profile"></i>My Profile</li>
    <li onClick={handleLogout}><i className="icon-logout"></i> Logout</li>
  </ul>

  return (authenticated && window.location.pathname.split('/')[1]!=="auth") ? (
    <div className="app-sidebar">
      <div className="app-sidebar__brand-wrapper">
        <img
          src={logo}
          className="app-sidebar__brand-logo"
          alt="Brand Logo"
          onClick={()=>window.location.replace(window.location.origin)}
        />
      </div>
      <Menu
        onClick={handleClick}
        selectedKeys={[`/${pathname.split('/')[1]}`]}
        mode="inline"
        className="app-sidebar__container"
      >
        <Menu.Item key={appRoutes.TOPICS}>
          <i className="icon-topics" />
          <div className="app-sidebar__menu-title">Topics</div>
        </Menu.Item>
        <Menu.Item key={appRoutes.QUESTIONS}>
          <i className="icon-questions" />
          <div className="app-sidebar__menu-title">Questions</div>
        </Menu.Item>
        <Menu.Item key={appRoutes.HIRINGTEST}>
          <i className="icon-hiring-test" />
          <div className="app-sidebar__menu-title">Hiring Test</div>
        </Menu.Item>
        <Menu.Item key={appRoutes.CERTIFICATES}>
          <i className="icon-certificates" />
          <div className="app-sidebar__menu-title">Certificates</div>
        </Menu.Item>
        <Menu.Item key={appRoutes.USERS}>
          <i className="icon-users" />
          <div className="app-sidebar__menu-title">Users</div>
        </Menu.Item>
        <Menu.Item key={appRoutes.HELP}>
          <i className="icon-help" />
          <div className="app-sidebar__menu-title">Help</div>
        </Menu.Item>

        <Menu.Item id="profile-link" key={appRoutes.PROFILE}>
          {
            user?.imageUrl||profile?.imageUrl ?
              <img src={user?.imageUrl||profile?.imageUrl} alt="" /> :
              <i className="icon-users" />
          }
          <div className="app-sidebar__menu-title">{user?.fullName || "Admin tiger"}</div>
          <div className="admin-badge app-sidebar__menu-title">Admin</div>
          <Popover placement="rightTop" content={popoverContent} trigger="click">
            <Button type="text" className="popover-btn" onClick={(e)=>e.stopPropagation()}><EllipsisOutlined /></Button>
          </Popover>
        </Menu.Item>
      </Menu>
    </div>
  ) : null;
}

export default withRouter(AuthContainer(Navbar));