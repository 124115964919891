import {serializable, alias, object, list, primitive} from 'serializr'

export class Topic { 
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('description', primitive()))
    description?: string;

    @serializable(alias('image_url', primitive()))
    imageUrl?: string;

    @serializable(alias('status', primitive()))
    status?: string;

    @serializable(alias('new_questions', primitive()))
    newQuestions?: number;

    @serializable(alias('no_of_questions', primitive()))
    noOfQuestions?: number;

    @serializable(alias('no_of_difficult_questions', primitive()))
    noOfDifficultQuestions?: number;

    @serializable(alias('no_of_medium_questions', primitive()))
    noOfMediumQuestions?: number;

    @serializable(alias('no_of_easy_questions', primitive()))
    noOfEasyQuestions?: number;

    @serializable(alias('no_of_tests', primitive()))
    noOfTests?: number;

    @serializable(alias('created_by', primitive()))
    createdBy?: string;

    @serializable(alias('proposed_by', primitive()))
    proposedBy?: string;
}

export class Topics{
    @serializable(alias("topics", list(object(Topic))))
    topics?: Topic[];
}