import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button } from "antd";
import { withRouter,useHistory } from "react-router-dom";
import { HOME, LOGIN, VERIFY } from "../../../routes/routeConstants/appRoutes";
import { resetSchema } from "../Validations";
import { User } from "../../../models/Auth/user.model";
import AuthService from "../../../services/AuthService/auth.service";

const ResetForm = (props:any) => {
    const email=props.location.state.data.email
    const { user, error, loading, resetPassword } = AuthService();
    const initialValue = {
        password1: "",
        password2: ""
    }
    const onSubmit=(data:User)=>{
        resetPassword({...data,email})
    }
    return (
        <div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={resetSchema}
            >
                <Form className="reset__form">
                    <h2 className="form-header reset">Reset Password</h2>
                    <InputField type="password" name="password1" placeholder="New Password" />
                    <InputField type="password" name="password2" placeholder="Confirm Password" />
                    <Button type="primary" htmlType="submit">Reset Password</Button>
                </Form>
            </Formik>
        </div>
    )
}

export default ResetForm;