import { Button, Row } from 'antd'
import React, { useEffect } from 'react'
import {
    CheckCircleTwoTone,
    SearchOutlined,
    LeftOutlined
} from '@ant-design/icons'
import { useHistory, useRouteMatch } from 'react-router'
import './proposedtopic.scss'
import TopicService from '../../../services/TopicService/topic.service'
import { NotificationTypes } from '../../../enums/notificationTypes'
import Notification from "../../../shared/components/Notification";

const ProposedTopic = () => {
    const {topic,getTopic,editTopic, error,loading} = TopicService();
    const history = useHistory()
    let match = useRouteMatch();
    let topicId = window.location.pathname.split('/')[3]

    const editStatus=(status:string)=>{
        editTopic({status:status},topicId).then(()=>{
            if(error){
                Notification({
                    message: "Status",
                    description: "Error updating status",
                    type: NotificationTypes.ERROR,
                });     
            }else{
                Notification({
                    message: "Status",
                    description: "Status updated successfully",
                    type: NotificationTypes.SUCCESS,
                }); 
                history.goBack()
            }
        })
    }

    useEffect(() => {
        getTopic(topicId)
    }, [topic?.status])

    return (
        <div className="app-wrapper proposed__topic">
            <div className="proposed__topic-header">
                <div className="title">
                    <div className="">
                        <LeftOutlined onClick={history.goBack} />
                        <h1 onClick={history.goBack}>
                            {topic?.name}
                        </h1>
                    </div>
                    <div className="">
                        <p>CREATED BY: <span>
                            {topic?.proposedBy}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="proposed__topic-content">
                <div className="texts">
                    <h3>{topic?.description}</h3>
                </div>
                <div className="image">
                    <img src={topic?.imageUrl} style={{ height: "320px", width: "100%" }} alt="" />
                    <div className="actions">
                        <Button type="primary" className="danger" onClick={() => {editStatus("rejected") }}>Reject</Button>
                        <Button type="primary" className="success" onClick={() => {editStatus("approved") }}>Approve</Button>
                    </div>
                </div>
                <div className="edit-icon" onClick={() => {history.push(`${match.url}/edit`,topic)}}>
                    <i className="icon-edit-2"></i>
                </div>
            </div>
        </div>
    )
}

export default ProposedTopic
