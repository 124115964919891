import * as Yup from "yup";

export const createTestValidation = (noOfQuestions:number) => Yup.object().shape({
    topicName: Yup.string()
        .required('Topic name is required!'),
    name: Yup.string()
        .required('Test name is required!'),
    maxNoOfAttempts: Yup.number()
        .positive()
        .required('Maxium attempts is required!'),
    minPassPercent: Yup.number()
        .positive()
        .max(100)
        .required('Minimum pass percent is required!'),
    numberOfQuestions: Yup.number()
        .positive()
        .min(10,  'Minimum 10 questions are needed to create a test')
        .max(noOfQuestions, 'Number of questions can\'t exceed the number of available questions in the difficulty level')
        .required('Number of questions is required!'),
    timePerQuestion: Yup.number()
        .positive()
        .required('Time per question is required!'),
})