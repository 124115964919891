export const ApiRoutes = {
    BASE_URL:process.env.REACT_APP_API_BASE_URL,
    USER_LOGIN: "/admin/sign_in",
    USER_FORGOT_PASSWORD: "/admin/password/forgot",
    USER_VERIFY_OTP:"/admin/password/verify_otp",
    USER_RESET_PASSWORD: "/admin/password/reset",
    USER_SIGNOUT: "/admin/sign_out",
    GET_TOPICS:"/topics_management/admin/topics",
    GET_PROPOSED_TOPICS:"/topics_management/admin/proposed_topics",
    GET_TOPIC:"/topics_management/admin/topics/:id",
    GET_PROPOSED_TOPIC:"/topics_management/admin/proposed_topics/:id",
    GET_QUESTIONS:"/questions_management/admin/questions",
    GET_TOPIC_QUESTIONS:"/questions_management/admin/topics/:topicId/questions",
    GET_TOPIC_QUESTION:"/questions_management/admin/topics/:topicId/questions/:questionId",
    LIST_TOPICS:"/topics_management/admin/all_topics",
    LIST_USERS:"/user_management/admin/users",
    LIST_TESTS:"/tests_management/admin/all_tests",
    CREATE_QUESTION:"/questions_management/admin/topics/:topicId/questions",
    GET_QUESTION:"/questions_management/admin/questions/:id",
    GET_TESTS:"/tests_management/admin/topics/:topicId/tests",
    GET_HIRING_TESTS:"/tests_management/admin/all_tests?type=hiring_test",
    GET_HIRING_TEST:"/tests_management/admin/tests/:testId/basic_dashboard_details",
    GET_HIRING_TEST_USERS:"/attempts_management/admin/tests/:testId/attempts",
    DELETE_TEST:"/tests_management/admin/tests/:testId",
    GET_USER_REPORT:"/report_management/admin/attempts/:attemptId/user_answers",
    GET_CERTIFICATE_TESTS:"/tests_management/admin/all_tests?type=certificate",
    CREATE_OPTION:"/questions_management/admin/options",
    UPDATE_OPTION:"/questions_management/admin/options/:id",
    GET_USERS:"/user_management/admin/users",
    GET_USER:"/user_management/admin/users/:id/user_basic_details",
    GET_USER_DEPLOYED_TESTS:"/user_management/admin/users/:userId/user_tests",
    GET_USER_ATTEMPTED_TESTS:"/user_management/admin/users/:userId/user_attempts",
    GET_USER_CERTIFICATE:"/user_management/admin/users/:userId/tests/:testId/user_certificate",
    GET_USER_SUBMITTED_QUESTIONS:"/user_management/admin/users/:userId/suggested_questions",
    GET_HELP_TABS:"/meta/help_tabs",
    GET_HELP_TAB:"/meta/help_tabs/:id",
    GET_PROFILE:"/user_management/admin/profile",
}
