import React, { useEffect } from 'react'
import { TestTypes } from '../../../../enums/testTypes'
import UserService from '../../../../services/UserService/user.service'
import './deployedtests.scss'

const DeployedTests = () => {
    const { getUserDeployedTests, userTests } = UserService()
    const userId = window.location.pathname.split('/')[2];

    useEffect(() => {
        getUserDeployedTests(userId)
    }, [])

    return (
        <div className="deployed__tests">
            {
                userTests?.userTest?.map((test) => (
                    <div className="deployed__tests-card">
                        <h2>{test.name}
                        {
                            test?.testType==="certificate"||test?.testType==TestTypes.HIRING_CERTIFICATE&&
                            <i className="icon-certificates"></i>
                        }
                        </h2>
                        <p className={test?.testType==TestTypes.HIRING_CUSTOM?"custom":"certificate"}>{
                            test?.testType==="certificate"?
                            <><span className="certificate">Certification</span></>:
                            <><span className={test?.testType==TestTypes.HIRING_CUSTOM?"custom":"certificate"}>{test?.testType==TestTypes.HIRING_CUSTOM?"Custom Test":"Certification"}</span>{test?.noOfQuestions}{" "}Questions</>
                        }</p>
                    </div>
                ))
            }
        </div>
    )
}

export default DeployedTests
