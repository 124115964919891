import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Profile } from "../../models/Profile/profile.model";
import { deserialize } from "serializr";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import LocalStorage from "../../shared/localStorage";

const ProfileService = () => {
    const [profile, setProfile] = useState<Profile>()
    const [error, setError] = useState<Error>();
    const [loading, setLoading] = useState(false);

    const getProfile = () => {
        setLoading(true);
        return axiosInstance
            .get(ApiRoutes.GET_PROFILE)
            .then((response) => {
                const _profile = deserialize(Profile, response.data["admin"]);
                setProfile(_profile);
                store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: _profile,
					},
				});
                LocalStorage.setItem("tiger-token", _profile)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const editProfile = (profile:FormData) => {
        setLoading(true);
        return axiosInstance
            .put(ApiRoutes.GET_PROFILE,profile)
            .then((response) => {
                const _profile = deserialize(Profile, response.data["admin"]);
                setProfile(_profile);
                store.dispatch({
					type: AUTHENTICATED,
					payload: {
						authenticated: true,
						user: _profile,
					},
				});
                LocalStorage.setItem("tiger-token", _profile)
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        error,
        loading,
        getProfile,
        profile,
        editProfile
    };
};

export default ProfileService;
