import React, { useEffect } from 'react'
import './approved.scss'
import AuthContainer from '../../../../store/container/AuthContainer'
import { Table, Switch } from 'antd';
import QuestionService from '../../../../services/QuestionService/question.service';
import { QuestionTypes } from '../../../../enums/questionTypes';
import ParagraphEllipsis from '../../../../shared/components/ParagraphEllipsis';

const Approved = () => {
  const { error, loading, getTopicQuestions, questions, editTopicQuestion } = QuestionService();
  const topicId = window.location.pathname.split('/')[2]

  useEffect(() => {
    getTopicQuestions(topicId, "approved")
  }, [])

  const onChange = (e:any,id:string) => { 
    editTopicQuestion(topicId,id,{status:QuestionTypes.REJECTED},  ()=>{}, ()=>{}).then(()=>{
      getTopicQuestions(topicId, "approved")
    })
  }

  const columns = [
    {
      title: 'QUESTION ID',
      dataIndex: 'id',
    },
    {
      title: 'QUESTION',
      dataIndex: 'title',
      render: ((_: any, row: any) => (
        <ParagraphEllipsis text={row?.title}></ParagraphEllipsis>
      )),
    },
    {
      title: 'TOPIC NAME',
      dataIndex: 'topicTest',
    },
    {
      title: 'CREATED BY',
      dataIndex: 'createdBy',
    },
    {
      title: 'NUMBER OF TIMES USED IN TEST',
      dataIndex: 'noOfTimesUsed',
      render: ((_: any, row: any) =>
      (
          <div className={`status`}>
            <p>
              {row?.noOfTimesUsed}
            </p>
          </div>
      ))
    },
    {
      title: '',
      dataIndex: 'isActive',
      render: ((_: any, row: any) =>
      (
        <div>
          <span className="">
            <Switch defaultChecked={true} onChange={(e)=>onChange(e,row?.id)} />
          </span>
        </div>
      ))
    },
  ];

  return (
    <div className="approved__questions">
      <div className="questions__list">
        <Table onRow={(record, index) => ({
          // onClick: (event) => getTopicQuestion(topicId,`${record?.id}`)
        })}
          columns={columns} 
          dataSource={questions?.questions} 
          size="middle" 
          pagination={false}
           scroll={{ y: 440 }} loading={loading}/>
      </div>
    </div>
  )
}

export default AuthContainer(Approved)
