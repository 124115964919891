import { Button, Col, Row } from 'antd'
import { Field, FieldArray, Form, Formik, FormikProps, FormikValues } from 'formik'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router'
import QuestionService from '../../../services/QuestionService/question.service'
import InputField from '../InputField'

import { Upload, Input, Select, Radio, Space } from 'antd';
import { Option as OptionModel } from '../../../models/Questions/question.model'
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from '../../../enums/notificationTypes'
import './questionform.scss'
import { convertJSONToFormData } from '../../utils/formDataConvertor'
import { questionValidation } from './questionValidation'
import { removeUndefined } from '../../utils/removeUndefinied'

const { TextArea } = Input;

const QuestionForm = (props: any) => {
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const editableQuestion = props?.question;
    const history = useHistory()
    const { createQuestion, createOption, updateOption, newOption, editQuestion, editTopicQuestion, loading, error, questionError, questionChangeLoading } = QuestionService()
    let topicId = window.location.pathname.split('/')[2]
    let module = window.location.pathname.split('/')[1]
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [url, setUrl] = useState("")

    const initialValues = {
        optionsArray: editableQuestion ?
            editableQuestion?.options : [
                {
                    "id": "00",
                    "text": null,
                    "imageUrl": "null"
                },
                {
                    "id": "01",
                    "text": null,
                    "imageUrl": "null"
                },
                {
                    "id": "02",
                    "text": null,
                    "imageUrl": "null"
                },
                {
                    "id": "03",
                    "text": null,
                    "imageUrl": "null"
                },
            ],
        title: editableQuestion?.title,
        image: editableQuestion?.imageUrl,
        correct_answer_id: editableQuestion?.correctAnswer?.id,
        option_1: editableQuestion?.options[0]?.text,
        option_2: editableQuestion?.options[1]?.text,
        option_3: editableQuestion?.options[2]?.text,
        option_4: editableQuestion?.options[3]?.text,
        tags: editableQuestion?.tags.toString(),
        difficulty_level: editableQuestion?.difficultyLevel || "Medium"
    }

    const questionSuccessCallback = () => {
        Notification({
            message: `Question  ${editableQuestion ? `edited` : `created`} successfully`,
            description: "",
            type: NotificationTypes.SUCCESS,
        });
        history.goBack()
    }

    const onSubmit = (values: FormikValues) => {
        const payload = removeUndefined({ ...values });
        (typeof payload?.image === "string" || typeof payload?.image === undefined) && delete payload?.image;
        payload.options = payload?.optionsArray.map((data: any, i: number) => {
            if (typeof data?.id == "number") {
                return data?.id
            }
        })
        delete payload?.option_1;
        delete payload?.option_2;
        delete payload?.option_3;
        delete payload?.option_4;
        payload.tags = typeof payload?.tags === "string" && payload?.tags?.split(',')
        payload.difficulty_level = payload?.difficulty_level.toLowerCase();
        
        editableQuestion && module !== "topics" ?
            editQuestion(editableQuestion?.id, convertJSONToFormData({ "question": payload }), questionSuccessCallback, ()=>{}) :
            editableQuestion && module === "topics" ?
                editTopicQuestion(topicId, editableQuestion?.id, convertJSONToFormData({ "question": payload }), questionSuccessCallback, ()=>{}) :
                createQuestion(convertJSONToFormData({ "question": payload }), topicId, questionSuccessCallback, ()=>{});

                // TODO validate errors
        // if (questionError) {
        //     Notification({
        //         message: "Error",
        //         description: `Error occured while ${editableQuestion ? `editing` : `creating`} a question, please try again!`,
        //         type: NotificationTypes.ERROR,
        //     });
        // } else {
        //     Notification({
        //         message: `Question  ${editableQuestion ? `edited` : `created`} successfully`,
        //         description: "",
        //         type: NotificationTypes.SUCCESS,
        //     });
        //     history.goBack()
        // }
    }

    return (
        <div className="topic-form__container">
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={questionValidation}
                enableReinitialize
            >
                {({ values, setFieldValue, dirty, isValid, resetForm, errors }) => (
                    <Form className="create__form">
                        <div className="create__form-fields">
                            <div className="questions">
                                <div className="title">
                                    <InputField type="textarea" name="title" placeholder="Question" rows={1} maxLength={200} />
                                    <Field
                                        as={Upload}
                                        maxCount={1}
                                        multiple={false}
                                        onChange={(info: any) => {
                                            const { status } = info.file;
                                            if (status !== 'uploading' && status !== 'removed') {
                                                setFieldValue("image", info?.file)
                                                setUrl(URL.createObjectURL(info?.file))
                                            } else {
                                                setFieldValue("image", "")
                                                setUrl("")
                                            }
                                        }}
                                        accept="image/*"
                                        name="image"
                                        beforeUpload={() => false}
                                    >
                                        {
                                            typeof values?.image == "string" ?
                                                <img className="preview__image relative" src={values?.image} alt="" /> :
                                                <></>
                                        }
                                        <h3 className="ant-upload-text">
                                            <i className="icon-image"></i>
                                        </h3>
                                    </Field>
                                </div>
                                <div className="difficulty-level">
                                    <InputField type="text" name="tags" placeholder="Tags (Optional)" />
                                    <div>
                                        <p className='field-label'>Question difficulty level</p>
                                        <Radio.Group defaultValue={"Medium"} onChange={(e: any) => setFieldValue("difficulty_level", e.target.value)}>
                                            <Radio value={"Easy"}>Easy</Radio>
                                            <Radio value={"Medium"}>Medium</Radio>
                                            <Radio value={"Hard"}>Hard</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="answers">
                                <Row>
                                    <Col span={10}>
                                        <h2>Your Answers</h2>
                                    </Col>
                                    <Col span={3}>
                                        <p>Correct Answer</p>
                                    </Col>
                                    <Col span={2}>
                                        <p>Image</p>
                                    </Col>
                                    <Col span={2}>
                                        <p>Delete</p>
                                    </Col>
                                </Row>
                                <Radio.Group name="correct_answer_id" 
                                 onChange={(e) => {
                                    e.target.value?.[0] !== "0" &&
                                    setFieldValue("correct_answer_id", e.target.value)
                                 }} 
                                 defaultValue={editableQuestion?.correctAnswer?.id}>
                                    <FieldArray name='optionsArray'
                                        render={(arrayHelpers) => (
                                            <Space direction="vertical">
                                                {
                                                    values?.optionsArray?.map((localOption: any, index: number) => (
                                                        <Row className="answer-row">
                                                            <Col span={13} className="answer-input">
                                                                <Radio value={localOption?.id}></Radio>
                                                                <InputField className='answer-input-field' type="text" name={`optionsArray.${index}.text`} placeholder={`Option ${index + 1}`}
                                                                    onFocus={(e: any) => setSelectedIndex(index)}
                                                                    onBlur={(e: any) => {
                                                                        localOption?.id?.[0] != "0" ?
                                                                            (updateOption(convertJSONToFormData({ "option": { text: e.target.value } }), values?.optionsArray[index].id, index,
                                                                                (newOption: OptionModel, index: number) => {
                                                                                    setFieldValue(`optionsArray.${index}`, newOption)
                                                                                },
                                                                                () => { })) :
                                                                            (createOption(
                                                                                e.target.value, index,
                                                                                (newOption: OptionModel, index: number) => {
                                                                                    setFieldValue(`optionsArray.${index}`, newOption)
                                                                                },
                                                                                () => { }))
                                                                    }} />
                                                            </Col>
                                                            <Col span={2}>
                                                                <Field
                                                                    as={Upload}
                                                                    multiple={false}
                                                                    maxCount={1}
                                                                    onChange={(info: any) => {
                                                                        const { status } = info.file;
                                                                        if (status !== 'uploading' && status !== 'removed') {
                                                                            setFieldValue(`option_${index + 1}_imageUrl`, info.file)
                                                                            localOption.id?.[0] !== "0" ?
                                                                                updateOption(convertJSONToFormData({ "option": { text: localOption?.text, image: info?.file } }), values?.optionsArray[index].id, index,
                                                                                    (newOption: OptionModel, index: number) => {
                                                                                        setFieldValue(`optionsArray.${index}`, newOption)
                                                                                    },
                                                                                    () => { }) :
                                                                                createOption(
                                                                                    "", index,
                                                                                    (newOption: OptionModel, index: number) => {
                                                                                        setFieldValue(`optionsArray.${index}`, newOption)
                                                                                    },
                                                                                    () => { }, info?.file)
                                                                        } else {
                                                                            setFieldValue(`option_${index + 1}_imageUrl`, null)
                                                                            updateOption(convertJSONToFormData({ "option": { text: localOption?.text, image: null } }), values?.optionsArray[index].id, index,
                                                                                    (newOption: OptionModel, index: number) => {
                                                                                        setFieldValue(`optionsArray.${index}`, newOption)
                                                                                    },
                                                                                    () => { })
                                                                        }
                                                                    }}
                                                                    accept="image/*"
                                                                    name={`option_${index + 1}_imageUrl`}
                                                                    beforeUpload={() => false}
                                                                >
                                                                    {
                                                                        localOption?.imageUrl && localOption?.imageUrl!=="null" &&
                                                                        <img className="preview__image" src={localOption?.imageUrl} alt="" />
                                                                    }
                                                                    <i className="icon-image"></i>
                                                                </Field>
                                                            </Col>
                                                            <Col span={2}>
                                                                <i className="icon-delete" onClick={() => {
                                                                    setFieldValue("optionsArray", values?.optionsArray.filter((data: any, i: number) => i !== index))
                                                                    if(localOption?.id===values?.correct_answer_id) setFieldValue("correct_answer_id", null)
                                                                }}></i>
                                                            </Col>
                                                        </Row>
                                                    ))
                                                }
                                            </Space>
                                        )} />
                                </Radio.Group>
                                {
                                    values?.optionsArray.length < 5 ?
                                        <Button type="primary" onClick={() => {
                                            if (values?.optionsArray.length < 5) {
                                                setFieldValue("optionsArray", [...values?.optionsArray, { "text": null, "imageUrl": "null", "id": "0" + values?.optionsArray?.length }])
                                            }
                                        }}>Add Answer</Button>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="create__form-actions">
                            <Button type="text" onClick={history.goBack}>Cancel</Button>
                            <Button 
                            type="primary" 
                            loading={questionChangeLoading || loading}
                            disabled={!isValid} 
                            htmlType="submit">Save</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default QuestionForm
